import dayjs from 'dayjs'

// Components
import {
  DateEditor,
  NumberEditor,
  PercentageEditor,
  PriceEditor,
  TextEditor,
  renderSelectEditor,
} from '../../components/CustomEditor'

// Utils
import { GROSS_OR_NET, YES_OR_NO } from '../../utils/constants'
import { formatCurrency } from '../../utils/formatters'

export const DEFAULT_COLUMNS = [
  {
    field: 'periodFrom',
    header: 'Period From',
    body: (row) => (row.periodFrom ? dayjs(row.periodFrom).format('MM/DD/YYYY') : '-'),
    editor: DateEditor,
    style: { minWidth: '120px' },
  },
  {
    field: 'periodTo',
    header: 'Period To',
    body: (row) => (row.periodTo ? dayjs(row.periodTo).format('MM/DD/YYYY') : '-'),
    editor: DateEditor,
    style: { minWidth: '120px' },
  },
  {
    field: 'minimumAssets',
    header: 'Minimum Assets',
    editor: PriceEditor,
    body: (rowData) => formatCurrency(rowData.minimumAssets),
    style: { minWidth: '200px' },
  },
  {
    field: 'minimumBasedOn',
    header: 'Minimum Based On',
    editor: (options) =>
      renderSelectEditor(
        [
          { label: 'Beginning', value: 'Beginning' },
          { label: 'Ending', value: 'Ending' },
          { label: 'Both', value: 'Both' },
        ],
        options.rowData.minimumBasedOn,
        'Select an Option',
        options,
      ),
    style: { minWidth: '200px' },
  },
  // {
  //   field: 'tolerance',
  //   header: 'Tolerance',
  //   editor: PercentageEditor,
  //   body: (rowData) =>
  //     rowData.tolerance !== null ? `${(rowData.tolerance * 100).toFixed(2)}%` : '-',
  //   style: { minWidth: '100px' },
  // },
  {
    name: 'minimumPolicyMonths',
    label: 'Minimum Policy',
    editor: NumberEditor,
    style: { minWidth: '100px' },
  },
  {
    field: 'scf',
    header: 'SCF',
    editor: PercentageEditor,
    body: (rowData) => (rowData.scf !== null ? `${(rowData.scf * 100).toFixed(2)}%` : '-'),
    style: { minWidth: '100px' },
  },
  {
    field: 'scfInflowPolicyMonths',
    header: 'SCF Inflow Policy',
    editor: NumberEditor,
    style: { minWidth: '170px' },
  },
  {
    field: 'scfOutflowPolicyMonths',
    header: 'SCF Outflow Policy',
    editor: NumberEditor,
    style: { minWidth: '170px' },
  },
  {
    field: 'secPerf',
    header: 'SEC 1/5/10 Perf',
    editor: (options) =>
      renderSelectEditor(
        [
          { label: 'Yes - Annualized', value: 'Yes - Annualized' },
          { label: 'Yes - Cumulative', value: 'Yes - Cumulative' },
          { label: 'Yes - Annualized Cumulative', value: 'Yes - Annualized Cumulative' },
          { label: 'No', value: 'No' },
        ],
        options.rowData.secPerf,
        'Select an Option',
        options,
      ),
    style: { minWidth: '250px' },
  },
  {
    field: 'returnTypesShown',
    header: 'Return Types Shown',
    editor: TextEditor,
    style: { minWidth: '170px' },
  },
  // {
  //   field: 'calcPerformance',
  //   header: 'Calc Performance',
  //   editor: (options) =>
  //     renderSelectEditor(
  //       [
  //         { label: 'Gross Only', value: 'Gross Only' },
  //         { label: 'Net Only', value: 'Net Only' },
  //         { label: 'Both', value: 'Both' },
  //       ],
  //       options.rowData.calcPerformance,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'includeInAppendix',
  //   header: 'Include In Appendix',
  //   editor: (options) =>
  //     renderSelectEditor(
  //       YES_OR_NO,
  //       options.rowData.includeInAppendix,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'nfpAccountspp',
  //   header: 'NFP Accounts PP',
  //   editor: (options) =>
  //     renderSelectEditor(
  //       [
  //         { label: 'Include', value: 'Include' },
  //         { label: 'Exclude', value: 'Exclude' },
  //         { label: 'N/A', value: 'N/A' },
  //       ],
  //       options.rowData.nfpAccountspp,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  {
    field: 'nfpModelFee',
    header: 'NFP Model Fee',
    editor: PercentageEditor,
    body: (rowData) =>
      rowData.nfpModelFee !== null ? `${(rowData.nfpModelFee * 100).toFixed(2)}%` : '-',
    style: { minWidth: '150px' },
  },
  {
    field: 'modelFee',
    header: 'Model Fee',
    editor: PercentageEditor,
    body: (rowData) =>
      rowData.modelFee !== null ? `${(rowData.modelFee * 100).toFixed(2)}%` : '-',
    style: { minWidth: '150px' },
  },
  {
    field: 'statisticsBasedOn',
    header: 'Statistics Based On',
    options: GROSS_OR_NET,
    editor: (options) =>
      renderSelectEditor(
        GROSS_OR_NET,
        options.rowData.statisticsBasedOn,
        'Select an Option',
        options,
      ),
    style: { minWidth: '200px' },
  },
  {
    field: 'currency',
    header: 'Currency',
    editor: (options) =>
      renderSelectEditor(
        [
          { label: 'ARS', value: 'ARS' },
          { label: 'AUD', value: 'AUD' },
          { label: 'BRL', value: 'BRL' },
          { label: 'CAD', value: 'CAD' },
          { label: 'CHF', value: 'CHF' },
          { label: 'CNY', value: 'CNY' },
          { label: 'DKK', value: 'DKK' },
          { label: 'EUR', value: 'EUR' },
          { label: 'GBP', value: 'GBP' },
          { label: 'IDR', value: 'IDR' },
          { label: 'ILS', value: 'ILS' },
          { label: 'INR', value: 'INR' },
          { label: 'JPY', value: 'JPY' },
          { label: 'MXN', value: 'MXN' },
          { label: 'MYR', value: 'MYR' },
          { label: 'NOK', value: 'NOK' },
          { label: 'NZD', value: 'NZD' },
          { label: 'PHP', value: 'PHP' },
          { label: 'RUB', value: 'RUB' },
          { label: 'SEK', value: 'SEK' },
          { label: 'THB', value: 'THB' },
          { label: 'TRY', value: 'TRY' },
          { label: 'USD', value: 'USD' },
        ],
        options.rowData.currency,
        'Select an Option',
        options,
      ),
    style: { minWidth: '200px' },
  },
  {
    field: 'dispersion',
    header: 'Disperson',
    editor: (options) =>
      renderSelectEditor(
        [
          { label: 'Asset Weighted', value: 'Asset Weighted' },
          { label: 'Equal Weighted', value: 'Equal Weighted' },
        ],
        options.rowData.dispersion,
        'Select an Option',
        options,
      ),
    style: { minWidth: '200px' },
  },
  {
    field: 'assetsDisplayedIn',
    header: 'Assets Displayed In',
    editor: (options) =>
      renderSelectEditor(
        [
          { label: 'Thousands', value: 'Thousands' },
          { label: 'Millions', value: 'Millions' },
          { label: 'Billions', value: 'Billions' },
        ],
        options.rowData.assetsDisplayedIn,
        'Select an Option',
        options,
      ),
    style: { minWidth: '200px' },
  },
  // {
  //   field: 'perfBasedFees',
  //   header: 'Perf Based Fees',
  //   editor: TextEditor,
  //   style: { minWidth: '170px' },
  // },
  // {
  //   field: 'creationDate',
  //   header: 'Creation Date',
  //   body: (row) => (row.creationDate ? dayjs(row.creationDate).format('MM/DD/YYYY') : '-'),
  //   editor: DateEditor,
  //   style: { minWidth: '170px' },
  // },
  // {
  //   field: 'inceptionDate',
  //   header: 'Inception Date',
  //   body: (row) => (row.inceptionDate ? dayjs(row.inceptionDate).format('MM/DD/YYYY') : '-'),
  //   editor: DateEditor,
  //   style: { minWidth: '170px' },
  // },
  // {
  //   field: 'terminationDatePp',
  //   header: 'Termination Date PP',
  //   body: (row) =>
  //     row.terminationDatePp ? dayjs(row.terminationDatePp).format('MM/DD/YYYY') : '-',
  //   editor: DateEditor,
  //   style: { minWidth: '170px' },
  // },
  // {
  //   field: 'strategyTypePp',
  //   header: 'Strategy Type PP',
  //   editor: (options) =>
  //     renderSelectEditor(
  //       [
  //         { label: 'LDPF', value: 'LDPF' },
  //         { label: 'BDPF', value: 'BDPF' },
  //         { label: 'Composite', value: 'Composite' },
  //       ],
  //       options.rowData.strategyTypePp,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'gipsReportType',
  //   header: 'GIPS Report Type',
  //   editor: (options) =>
  //     renderSelectEditor(
  //       [
  //         {
  //           label: 'Composite Report - Separate Accounts and Pooled Funds',
  //           value: 'Composite Report - Separate Accounts and Pooled Funds',
  //         },
  //         {
  //           label: 'Composite Report - Separate Accounts Only',
  //           value: 'Composite Report - Separate Accounts Only',
  //         },
  //         { label: 'Pooled Fund Report', value: 'Pooled Fund Report' },
  //         {
  //           label: 'Non-GIPS Composite Report - Separate Accounts and Pooled Funds',
  //           value: 'Non-GIPS Composite Report - Separate Accounts and Pooled Funds',
  //         },
  //         {
  //           label: 'Non-GIPS Composite Report - Separate Accounts Only',
  //           value: 'Non-GIPS Composite Report - Separate Accounts Only',
  //         },
  //         { label: 'Non-GIPS Pooled Fund Report', value: 'Non-GIPS Pooled Fund Report' },
  //       ],
  //       options.rowData.gipsReportType,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'holdingsTypePp',
  //   header: 'Holdings Type PP',
  //   editor: (options) =>
  //     renderSelectEditor(
  //       [
  //         { label: 'Domestic Only', value: 'Domestic Only' },
  //         { label: 'Foreign Only', value: 'Foreign Only' },
  //         { label: 'Domestic and Foreign', value: 'Domestic and Foreign' },
  //         {
  //           label: 'Domestic and Foreign Exposure Through Domestic Instruments',
  //           value: 'Domestic and Foreign Exposure Through Domestic Instruments',
  //         },
  //       ],
  //       options.rowData.holdingsTypePp,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'foreignWithholdingTax',
  //   header: 'Foreign Withholding Tax',
  //   options: GROSS_OR_NET,
  //   editor: (options) =>
  //     renderSelectEditor(
  //       GROSS_OR_NET,
  //       options.rowData.foreignWithholdingTax,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'subAdvisor',
  //   header: 'Sub-Advisor',
  //   editor: (options) =>
  //     renderSelectEditor(
  //       [
  //         { label: 'No', value: 'No' },
  //         {
  //           label: 'A sub-advisor was used for all periods presented.',
  //           value: 'A sub-advisor was used for all periods presented.',
  //         },
  //         { label: 'Use Customize Column', value: 'Use Customize Column' },
  //       ],
  //       options.rowData.subAdvisor,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'compositeDefinition',
  //   header: 'Composite Definition',
  //   editor: TextAreaEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'noMeaningfulBenchmark',
  //   header: 'No Meaningful Benchmark',
  //   editor: TextEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'rebalancingFrequency',
  //   header: 'Rebalancing Frequency',
  //   editor: (options) =>
  //     renderSelectEditor(
  //       [
  //         { label: 'Daily', value: 'Daily' },
  //         { label: 'Monthly', value: 'Monthly' },
  //         { label: 'Quarterly', value: 'Quarterly' },
  //         { label: 'Annually', value: 'Annually' },
  //       ],
  //       options.rowData.rebalancingFrequency,
  //       'Select an Option',
  //       options,
  //     ),
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'performanceExaminedFrom',
  //   header: 'Performance Examined From',
  //   body: (row) =>
  //     row.performanceExaminedFrom ? dayjs(row.performanceExaminedFrom).format('MM/DD/YYYY') : '-',
  //   editor: DateEditor,
  //   style: { minWidth: '170px' },
  // },
  // {
  //   field: 'performanceExaminedTo',
  //   header: 'Performance Examined To',
  //   body: (row) =>
  //     row.performanceExaminedTo ? dayjs(row.performanceExaminedTo).format('MM/DD/YYYY') : '-',
  //   editor: DateEditor,
  //   style: { minWidth: '170px' },
  // },
  // {
  //   field: 'bundledFeeDisclosure',
  //   header: 'Bundled Fee Disclosure',
  //   editor: TextEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'leverageOrDerivatives',
  //   header: 'Leverage or Derivatives',
  //   editor: TextAreaEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'pooledFundInceptionDate',
  //   header: 'Pooled Fund Inception Date',
  //   body: (row) =>
  //     row.pooledFundInceptionDate ? dayjs(row.pooledFundInceptionDate).format('MM/DD/YYYY') : '-',
  //   editor: DateEditor,
  //   style: { minWidth: '170px' },
  // },
  // {
  //   field: 'pooledFundExpenses',
  //   header: 'Pooled Fund Expenses',
  //   editor: TextEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'feeScheduleandExpenseRatio',
  //   header: 'Fee Schedule and Expense Ratio',
  //   editor: TextAreaEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'portabilityDate',
  //   header: 'Portability Date',
  //   body: (row) => (row.portabilityDate ? dayjs(row.portabilityDate).format('MM/DD/YYYY') : '-'),
  //   editor: DateEditor,
  //   style: { minWidth: '170px' },
  // },
  // {
  //   field: 'portabilityDisclosure',
  //   header: 'Portability Disclosure',
  //   editor: TextEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'modelFeeMethodology',
  //   header: 'Model Fee Methodology',
  //   editor: TextAreaEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'customize',
  //   header: 'Customize',
  //   editor: TextAreaEditor,
  //   style: { minWidth: '200px' },
  // },
  // {
  //   field: 'appendixCustomPp',
  //   header: 'Appendix Custom PP',
  //   editor: TextEditor,
  //   style: { minWidth: '200px' },
  // },
  {
    type: 'select',
    name: 'terminated',
    label: 'Terminated',
    options: YES_OR_NO,
    editor: (options) =>
      renderSelectEditor(YES_OR_NO, options.rowData.terminated, 'Select an Option', options),
    style: { minWidth: '200px' },
  },
  // {
  //   field: 'marketingGrowthDrawdown',
  //   header: 'Marketing Growth Drawdown',
  //   editor: TextEditor,
  //   style: { minWidth: '200px' },
  // },
]
