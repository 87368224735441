import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'

// Components
import { Button } from '../../components/Button'
import { ClientHeader } from '../../components/ClientHeader'
import { CustomLink } from '../../components/CustomLink'
import {
  DateEditor,
  PercentageEditor,
  renderSelectEditor,
  renderMultiSelectFilter,
} from '../../components/CustomEditor'
import { DataTable, DEFAULT_FILTER_OPTIONS } from '../../components/DataTable'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'

// Services
import {
  getClient,
  getClientPortfolioDataComposites,
  getClientBenchmarkDataPeriods,
} from '../../services/clients.service'
import {
  getClientBenchmarks,
  deleteBenchmarkData,
  getBenchmarkData,
  updateBenchmarkData,
} from '../../services/benchmarks.service'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

// Utils
import { REBALANCING_FREQUENCY_OPTIONS } from '../../utils/constants'
import { configureFilterQuery, hasAnyFilterSelected, toast } from '../../utils/helpers'

// Hooks
import { usePagination, useSorting } from '../../hooks/DataTableManagement'
import AddBenchmarkDataModal from './AddBenchmarkDataModal'

const DEFAULT_FILTERS = {
  composite: { value: [] },
  period: { value: [] },
  rebalancing_frequency: { value: [] },
}

const ClientBenchmarkData = observer(() => {
  // Context
  const { clientId } = useParams()
  const { canModifyData, isImportInProgress, client, setClient } = useContext(
    ClientDashboardStoreContext,
  )

  // Pagination
  const { pagination, setTotalRecords } = usePagination(50)
  const { perPage, currentPage } = pagination

  // Sorting
  const { sorting } = useSorting('period')
  const { sortedColumn } = sorting

  // State
  const [loadingClient, setLoadingClient] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [error, setError] = useState(null)
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  const [benchmarks, setBenchmarks] = useState([])
  const [composites, setComposites] = useState([])
  const [showAddRowModal, setShowAddRowModal] = useState(false)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const dataTable = useRef()

  const BENCHMARK_DATA_BASE_URL = `/clients/${clientId}/benchmark-data/?expand=benchmark,composite`

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated list of aum data; updates pagination.
   * @param {string} url
   */
  const getUpdatedBenchmarkData = async (url) => {
    const response = await getBenchmarkData(url, handleErrors, setLoadingData)

    if (response) {
      setTotalRecords(response.count)
      setData(response.results)
    }
  }

  /**
   * When the filter, sort, current page, or row count changes, get the updated list of data.
   */
  useEffect(() => {
    if (clientId) {
      const queryFilters = configureFilterQuery({
        composite: filters.composite || DEFAULT_FILTERS.composite,
        period: filters.period || DEFAULT_FILTERS.period,
        rebalancing_frequency:
          filters.rebalancing_frequency || DEFAULT_FILTERS.rebalancing_frequency,
      })

      getUpdatedBenchmarkData(
        `${BENCHMARK_DATA_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}&${queryFilters}`,
      )
    }
  }, [perPage, clientId, sortedColumn, currentPage, filters])

  useEffect(() => {
    const getClientData = async () => {
      const updatedClient = await getClient(clientId, setError, setLoadingClient)
      if (updatedClient) {
        setClient(updatedClient)
      }

      const [clientBenchmarks, clientComposites, clientPeriods] = await Promise.all([
        getClientBenchmarks(`/clients/${clientId}/benchmarks/?limit=99999`, handleErrors),
        getClientPortfolioDataComposites(clientId, handleErrors),
        getClientBenchmarkDataPeriods(clientId, handleErrors),
      ])

      const updatedBenchmarks = _.map(clientBenchmarks.results, (d) => ({
        label: d.name,
        value: d.id,
        id: d.id,
      }))
      const updatedComposites = _.map(clientComposites, (d) => ({
        label: d.name,
        value: d.id,
        id: d.id,
      }))
      const updatedPeriods = _.map(clientPeriods, (d) => ({
        label: dayjs(d).format('MM/DD/YYYY'),
        value: d,
        id: d,
      }))

      // Update editors and filters to include options
      const updatedColumns = [
        {
          field: 'benchmark.id',
          header: 'Benchmark',
          body: (rowData) => `${rowData.benchmark.name} (${rowData.benchmark.component})`,
          sortField: 'benchmark__name',
          sortable: true,
          style: { minWidth: '300px' },
        },
        {
          field: 'weight',
          header: 'Weight',
          sortable: true,
          editor: PercentageEditor,
          body: (rowData) =>
            rowData.weight !== null ? `${(rowData.weight * 100).toFixed(2)}%` : '-',
          style: { minWidth: '140px' },
        },
        {
          field: 'rebalancingFrequency',
          header: 'Rebalancing Frequency',
          sortable: true,
          body: (rowData) => rowData.rebalancingFrequency,
          style: { minWidth: '250px' },
          filterField: 'rebalancing_frequency',
          ...DEFAULT_FILTER_OPTIONS,
          editor: (options) =>
            renderSelectEditor(
              REBALANCING_FREQUENCY_OPTIONS,
              options.rowData.rebalancingFrequency,
              'Select a Frequency',
              options,
            ),
          filterElement: (options) =>
            renderMultiSelectFilter(
              REBALANCING_FREQUENCY_OPTIONS,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  rebalancing_frequency: {
                    value: selected,
                  },
                }))
              },
              'Filter by Frequency',
              options,
            ),
        },
        {
          field: 'composite.id',
          header: 'Composite',
          body: (rowData) => rowData.composite.name,
          sortField: 'composite__name',
          sortable: true,
          style: { minWidth: '250px' },
          filterField: 'composite',
          ...DEFAULT_FILTER_OPTIONS,
          editor: (options) =>
            renderSelectEditor(
              updatedComposites,
              options.rowData.composite.id,
              'Select a Composite',
              options,
            ),
          filterElement: (options) =>
            renderMultiSelectFilter(
              updatedComposites,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  composite: {
                    value: selected,
                  },
                }))
              },
              'Filter by Composite',
              options,
            ),
        },
        {
          field: 'period',
          header: 'Period',
          sortable: true,
          editor: DateEditor,
          style: { minWidth: '150px' },
          filterElement: (options) =>
            renderMultiSelectFilter(
              updatedPeriods,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  period: {
                    value: selected,
                  },
                }))
              },
              'Filter by Period',
              options,
            ),
        },
        {
          field: 'benchmarkReturn',
          header: 'Return',
          sortable: true,
          editor: PercentageEditor,
          body: (rowData) =>
            rowData.benchmarkReturn !== null
              ? `${(rowData.benchmarkReturn * 100).toFixed(2)}%`
              : '-',
          style: { minWidth: '140px' },
        },
      ]

      setBenchmarks(updatedBenchmarks)
      setComposites(updatedComposites)
      setColumns(updatedColumns)
    }

    getClientData()
  }, [clientId])

  /**
   * Handles row edit completion by updating the local `data` with new data and
   * submitting it to the backend.
   * @param {object} event
   */
  const onRowEditComplete = async ({ newData, index }) => {
    const updatedRow = {
      ...newData,
      benchmarkReturn: newData.benchmarkReturn || 0,
    }
    const oldBenchmarkData = [...data]
    const updatedData = [...data]

    if (newData.period instanceof Date) {
      const [date] = newData.period.toISOString().split('T')
      // eslint-disable-next-line no-param-reassign
      newData.period = date
    }

    // Optimistically update the data to prevent showing internal account values
    updatedData[index] = updatedRow
    setData(updatedData)

    await updateBenchmarkData(
      clientId,
      {
        ...updatedRow,
        benchmark: newData.benchmark.id,
        composite: newData.composite.id,
      },
      (m) => {
        // Reset back to previous value
        setData(oldBenchmarkData)
        handleErrors(m)
      },
      () => {},
      (m) => handleSuccess(m),
    )
  }

  return (
    <PageContainer>
      <StateContainer error={error} loading={loadingClient}>
        <div className="size-full">
          <ClientHeader client={client} />

          <div className="flex h-[calc(100vh-150px)] w-full flex-col bg-white px-4 pb-12 pt-6 sm:px-6 lg:px-8">
            <div className="flex size-full flex-col space-y-6">
              <div className="flex w-full flex-col justify-between sm:flex-row sm:items-center">
                <div className="flex flex-row items-center gap-2">
                  <h3 className="text-xl font-semibold leading-6 text-gray-900">Benchmark Data</h3>
                </div>

                {client?.latestSuccessfulImport !== null && (
                  <div className="mt-3 flex flex-row gap-2 sm:ml-4 sm:mt-0">
                    {hasAnyFilterSelected(filters) && (
                      <Button
                        label="Clear Filters"
                        background="bg-gray"
                        onClick={() => {
                          setFilters(DEFAULT_FILTERS)
                          getUpdatedBenchmarkData(
                            `${BENCHMARK_DATA_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                          )
                        }}
                      />
                    )}

                    {canModifyData && (
                      <Button label="Add Row" onClick={() => setShowAddRowModal(true)} />
                    )}

                    <CustomLink to={`/clients/${clientId}/imports/new`}>
                      {isImportInProgress ? 'View Import' : 'Import Data'}
                    </CustomLink>
                  </div>
                )}
              </div>

              <DataTable
                data={data}
                columns={columns}
                loading={loadingData}
                rowsPerPageOptions={[50, 100, 500, 1000]}
                pagination={pagination}
                sorting={sorting}
                loadingDelete={loadingDelete}
                onRowEditComplete={onRowEditComplete}
                onRowDeleteConfirm={(row) => {
                  deleteBenchmarkData(clientId, row.id, handleErrors, setLoadingDelete, () => {
                    handleSuccess('Benchmark data row deleted.')
                    getUpdatedBenchmarkData(
                      `${BENCHMARK_DATA_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                    )
                  })
                }}
                areRowsDeleteable={canModifyData}
                areRowsEditable={canModifyData}
                hoverEffect={false}
                rowDeleteConfirm={(row) => ({
                  title: `Delete AUM Data Row`,
                  type: 'warning',
                  message: (
                    <div className="flex flex-col">
                      <span className="">Are you sure you want to delete the row for:</span>
                      <div className="mt-1.5 flex gap-1">
                        Year:
                        <span className="font-semibold">{row.year}</span>
                      </div>
                    </div>
                  ),
                })}
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                ref={dataTable}
              />
            </div>
          </div>
        </div>
      </StateContainer>

      {showAddRowModal && (
        <AddBenchmarkDataModal
          benchmarks={benchmarks}
          client={client}
          closeModal={() => setShowAddRowModal(false)}
          composites={composites}
          onSuccess={() => {
            setShowAddRowModal(false)
            getUpdatedBenchmarkData(
              `${BENCHMARK_DATA_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
            )
          }}
          rebalancingFrequencies={REBALANCING_FREQUENCY_OPTIONS}
        />
      )}
    </PageContainer>
  )
})

export default ClientBenchmarkData
