import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { observer } from 'mobx-react'

// Components
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'
import RulesTable from './RulesTable'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

// Service
import { getClient } from '../../services/clients.service'
import { getClientComposites } from '../../services/composites.service'
import { getGlobalRules } from '../../services/global.service'

const ClientRules = observer(() => {
  // Context
  const { clientId } = useParams()
  const { canModifyData, setClient } = useContext(ClientDashboardStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [composites, setComposites] = useState([])
  const [globalRules, setGlobalRules] = useState(null)

  useEffect(() => {
    const getUpdatedData = async () => {
      setLoading(true)

      const [updatedClient, updatedComposites, updatedGlobalRules] = await Promise.all([
        getClient(clientId, setError, () => {}),
        getClientComposites(`/clients/${clientId}/composites?limit=99999`, setError, () => {}),
        getGlobalRules(setError, () => {}),
      ])

      if (updatedClient) {
        setClient(updatedClient)
      }

      if (updatedComposites)
        setComposites(_.map(updatedComposites.results, (c) => ({ label: c.name, value: c.id })))
      if (updatedGlobalRules) setGlobalRules(updatedGlobalRules)
      setLoading(false)
    }

    getUpdatedData()
  }, [])

  return (
    <PageContainer>
      <StateContainer error={error} loading={loading}>
        <div className="flex size-full flex-col overflow-y-auto bg-background">
          <div className="space-y-6">
            <RulesTable
              addLabel="Add Rule"
              baseUrl={`/clients/${clientId}/rules?expand=composite`}
              clientId={clientId}
              composites={composites}
              editable={canModifyData}
              title="Rules"
            />
          </div>
        </div>
      </StateContainer>
    </PageContainer>
  )
})

export default ClientRules
