import React from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'

// Components
import { DataTableMultiSelect } from '../DataTableMultiSelect'
import { DataTableRadioGroup } from '../DataTableRadioGroup'
import { StatusTag } from '../StatusTag'

dayjs.extend(utc)
dayjs.extend(timezone)

export const TextAreaEditor = (options) => (
  <div className="inline-flex w-full">
    <InputTextarea
      value={options.value || ''}
      onChange={(e) => options.editorCallback(e.target.value)}
      cols={30}
      rows={1}
      className="max-h-[76px] min-h-[40px]"
      autoResize
    />
  </div>
)

export const TextEditor = (options) => (
  <div className="inline-flex w-full">
    <InputText
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
      className="w-full"
    />
  </div>
)

export const DateEditor = (options) => {
  // Parse the date to ensure it's treated as UTC
  const dateValue = options.value ? dayjs(options.value, 'MM/DD/YYYY').toDate() : null

  return (
    <Calendar
      value={dateValue}
      onChange={(e) => options.editorCallback(e.value)}
      dateFormat="mm/dd/yy"
      placeholder="Select a date"
    />
  )
}

export const PercentageEditor = (options) => {
  const percentageValue = !Number.isNaN(options.value) ? parseFloat(options.value * 100) : null
  const handleValueChange = (e) => {
    const newValue = e.value ? e.value / 100 : null // Convert the input back to a decimal before sending to the BE
    options.editorCallback(newValue)
  }

  return (
    <InputNumber
      value={percentageValue}
      onValueChange={handleValueChange}
      mode="decimal"
      max={0.99999 * 100}
      minFractionDigits={1}
      maxFractionDigits={5}
      suffix="%"
    />
  )
}

export const PriceEditor = (options) => (
  <InputNumber
    value={options.value}
    onValueChange={(e) => options.editorCallback(e.value)}
    mode="currency"
    currency="USD"
    locale="en-US"
  />
)

export const NumberEditor = (options) => (
  <InputNumber
    value={options.value}
    onValueChange={(e) => options.editorCallback(e.value)}
    mode="decimal"
  />
)

const StatusItemTemplate = (option) => (
  <StatusTag value={option.label} severity={option.value ? 'success' : 'danger'} />
)

export const StatusEditor = (options) => (
  <Dropdown
    value={options.value}
    options={[
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ]}
    onChange={(e) => options.editorCallback(e.value)}
    placeholder="Select a Status"
    optionLabel="label"
    itemTemplate={StatusItemTemplate}
  />
)

export const SelectEditor = (options) => (
  <Dropdown
    value={options.value}
    options={options.options}
    onChange={(e) => options.editorCallback(e.value)}
    placeholder={options.placeholder}
    optionLabel="label"
    pt={{
      root: 'md:w-full',
    }}
  />
)

export const renderSelectEditor = (options, value, placeholder, rest) => (
  <SelectEditor {...rest} value={value} options={options} placeholder={placeholder} />
)

export const renderMultiSelectFilter = (options, handleChange, placeholder, rest) => (
  <DataTableMultiSelect
    {...rest}
    data={options}
    handleChange={handleChange}
    placeholder={placeholder}
    search
  />
)

export const renderRadioFilter = (options, handleChange, placeholder, rest) => (
  <DataTableRadioGroup
    {...rest}
    options={options}
    handleChange={handleChange}
    placeholder={placeholder}
  />
)

export const renderStatusTag = (data) => (
  <StatusTag value={data ? 'Yes' : 'No'} severity={data ? 'success' : 'danger'} />
)
