// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the portfolio data using the specified `url`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getPortfolioData = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new portfolio data item with the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createPortfolioData = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.post(`/clients/${clientId}/portfolio-data/`, payload)
    setSuccess('New row added.')
    setLoading(false)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Updates the portfolio data with the specified `portfolioId` and `payload`.
 * @param {string} clientId
 * @param {string} portfolioId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` for the new portfolio
 */
export const updatePortfolioData = async (
  clientId,
  portfolioId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(
      `/clients/${clientId}/portfolio-data/${portfolioId}/?expand=composite,account,portfolio`,
      payload,
    )
    setSuccess(`${response?.composite?.name || 'Portfolio'} updated.`)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the portfolio data with the specified `portfolioId` and `payload`.
 * @param {string} clientId
 * @param {string} portfolioId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deletePortfolioData = async (
  clientId,
  portfolioId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/clients/${clientId}/portfolio-data/${portfolioId}/`)
    setLoading(false)
    setSuccess()
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/** ---------------------------- Pending ----------------------------- */

/**
 * Gets the pending portfolio data using the specified `url`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getPendingPortfolioData = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the pending portfolio data with the specified `pendingPortfolioId` and `payload`.
 * @param {string} clientId
 * @param {string} pendingPortfolioId
 * @param {object} payload
 * @param {func} setSuccess
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` for the new portfolio
 */
export const updatePendingPortfolioData = async (
  clientId,
  pendingPortfolioId,
  payload,
  setSuccess = () => {},
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(
      `/clients/${clientId}/pending-portfolio-data/${pendingPortfolioId}/`,
      payload,
    )

    setSuccess(`${response?.compositeName || 'Portfolio'} updated.`)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a rules check against the portfolio data.
 * @param {string} clientId
 * @param {func} setSuccess
 * @param {func} setError
 * @param {func} setLoading
 */
export const createRulesCheckRun = async (
  clientId,
  setSuccess = () => {},
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/clients/${clientId}/rules-check-runs/?expand=client`)

    setSuccess(response)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets a rules check run for the specified `clientId`.
 * @param {string} clientId
 * @param {string} rulesCheckRunId
 * @param {func} setSuccess
 * @param {func} setError
 * @param {func} setLoading
 */
export const getRulesCheckRun = async (
  clientId,
  rulesCheckRunId,
  setSuccess = () => {},
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(
      `/clients/${clientId}/rules-check-runs/${rulesCheckRunId}?expand=client`,
    )

    setSuccess(response)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Marks rules check run as approved, sending portfolio data that is pending approval,
 * including any manually updated comments.
 * @param {string} clientId
 * @param {func} setSuccess
 * @param {func} setError
 * @param {func} setLoading
 */
export const approveRulesCheck = async (
  clientId,
  rulesCheckRunId,
  payload,
  setSuccess = () => {},
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(
      `/clients/${clientId}/rules-check-runs/${rulesCheckRunId}/?expand=client`,
      payload,
    )

    setSuccess('Rules check approved.')
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** ----------------------------- Import ----------------------------- */

/**
 * Uses the `url` to get cient data imports.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientDataImports = async (url, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a portfolio data import using the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const createPortfolioDataImport = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/clients/${clientId}/data-imports/?expand=created_by,last_updated_by,client`,
      payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )

    setLoading(false)
    setSuccess('Data import started.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets a portfolio data import using the specified `importId`.
 * @param {string} clientId
 * @param {string} importId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getPortfolioDataImport = async (
  clientId,
  importId,
  setError = () => {},
  setLoading = () => {},
  additionalExpand = '',
) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/clients/${clientId}/data-imports/${importId}?expand=created_by,last_updated_by,client,portfolio_data_changes,latest_qc_check${additionalExpand}`,
    )

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the portfolio data import with the specified `importId`.
 * @param {string} clientId
 * @param {string} importId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const updatePortfolioDataImport = async (
  clientId,
  importId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/clients/${clientId}/data-imports/${importId}/?expand=created_by,last_updated_by,client`,
      payload,
    )

    setLoading(false)
    setSuccess(data)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** ----------------------------- Exports ------------------------------- */

/**
 * Creates a data export using the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const createClientDataExport = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/clients/${clientId}/data-export/?expand=client`, payload)

    setLoading(false)
    setSuccess('Export started.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets a data export using the specified `exportId`.
 * @param {string} clientId
 * @param {string} exportId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientRollForwardExport = async (
  clientId,
  exportId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/clients/${clientId}/data-export/${exportId}/?expand=client`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
