export const YES_OR_NO = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

export const GROSS_OR_NET = [
  { label: 'Gross', value: 'Gross' },
  { label: 'Net', value: 'Net' },
]

export const REBALANCING_FREQUENCY_OPTIONS = [
  { id: 'Daily', label: 'Daily', value: 'Daily' },
  { id: 'Monthly', label: 'Monthly', value: 'Monthly' },
  { id: 'Quarterly', label: 'Quarterly', value: 'Quarterly' },
  { id: 'Annually', label: 'Annually', value: 'Annually' },
  { id: 'Semi-Annually', label: 'Semi-Annually', value: 'Semi-Annually' },
  { id: 'Single Benchmark', label: 'Single Benchmark', value: 'Single Benchmark' },
]

export const DISCLOSURE_DESCRIPTION_OPTIONS = [
  { id: 'Composites Only', label: 'Composites Only', value: 'Composites Only' },
  {
    id: 'Limited Distribution Pooled Funds Only',
    label: 'Limited Distribution Pooled Funds Only',
    value: 'Limited Distribution Pooled Funds Only',
  },
  {
    id: 'Broad Distribution Pooled Funds Only',
    label: 'Broad Distribution Pooled Funds Only',
    value: 'Broad Distribution Pooled Funds Only',
  },
  {
    id: 'Composites & Limited Distribution Pooled Funds Only',
    label: 'Composites & Limited Distribution Pooled Funds Only',
    value: 'Composites & Limited Distribution Pooled Funds Only',
  },
  {
    id: 'Composites & Broad Distribution Pooled Funds Only',
    label: 'Composites & Broad Distribution Pooled Funds Only',
    value: 'Composites & Broad Distribution Pooled Funds Only',
  },
  {
    id: 'Broad Distribution Pooled Funds and Limited Distribution Pooled Funds Only',
    label: 'Broad Distribution Pooled Funds and Limited Distribution Pooled Funds Only',
    value: 'Broad Distribution Pooled Funds and Limited Distribution Pooled Funds Only',
  },
  { id: 'All Three', label: 'All Three', value: 'All Three' },
]

export const REPORT_OPTIONS = [
  { label: 'Default', value: 'Default' },
  { label: 'Design 1', value: 'Design 1' },
  { label: 'Design 2', value: 'Design 2' },
]

export const ORIENTATION = [
  { label: 'Landscape', value: 'Landscape' },
  { label: 'Portrait', value: 'Portrait' },
]
