import React from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ArrowPathIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dayjs from 'dayjs'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../Button'

/**
 *
 * ImportDataChanges
 *
 */
const ImportDataChanges = ({ client, dataImport, resetPage }) => {
  if (dataImport?.status === 'Failed') {
    return (
      <div className="flex w-full flex-col items-center">
        <span className="pb-4 font-semibold">Data Import Failed.</span>

        {dataImport?.errors?.length > 0 && (
          <div>
            <ul>
              {_.map(dataImport.errors, (e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </div>
        )}

        {resetPage && (
          <div className="flex flex-row space-x-4 p-6">
            <Button label="Retry Import" onClick={resetPage} />
          </div>
        )}
      </div>
    )
  }
  if (dataImport?.status === 'Canceled') {
    return (
      <div className="flex w-full flex-col items-center justify-center">
        <span className="font-semibold">Data Import Canceled.</span>
      </div>
    )
  }

  const renderLoading = (label) => (
    <div className="flex size-full flex-col items-center justify-center space-y-2 pt-8">
      <span className="text-lg font-semibold text-gray-900">{label}</span>

      <span className="flex items-center pr-3">
        <div className="size-10">
          <svg className="size-10 text-gray-900 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
            <ArrowPathIcon className="size-10" aria-hidden="true" />
          </svg>
        </div>
      </span>
    </div>
  )

  const changes = _.values(dataImport?.portfolioDataChanges)
  const totalNewAccounts = _.reduce(changes, (t, d) => t + d.newAccounts.length, 0)
  const totalNewComposites = _.reduce(changes, (t, d) => t + d.newComposites.length, 0)
  const totalChangesToInclusion = _.reduce(
    changes,
    (t, d) => t + d.accountsIsIncludedChanged.length,
    0,
  )

  const isMissingHouseholdData =
    !dataImport?.isHouseholdDataImport &&
    client?.hasAllHouseholdData &&
    !dataImport.overrideExistingData
  const isAddingHouseholdData =
    dataImport?.isHouseholdDataImport &&
    !client?.hasAllHouseholdData &&
    !dataImport.overrideExistingData

  /**
   * Configure the action to display based on the data action.
   * @param {object} data
   */
  const configureAction = (data) => {
    if (data.action === 'update') return 'updated'
    if (data.action === 'add') return 'added'
    return 'did not change'
  }

  /**
   * Renders the data change block with the specified title and data.
   * @param {string} title
   * @param {object} data
   */
  const renderDataChangeBlock = (title, d) => (
    <div>
      <div className="flex flex-row items-center space-x-1">
        <span className="font-medium">{title}</span>
        <span className="inline-flex size-5 items-center justify-center rounded-full bg-blue text-xs/5 font-semibold text-white">
          {d.length}
        </span>
      </div>
      <ul>
        {_.map(_.sortBy(d), (i) => (
          <li key={i}>{i}</li>
        ))}
      </ul>
    </div>
  )

  return (
    <div className="flex size-full flex-col gap-3 text-sm">
      {dataImport?.errors?.length > 0 && (
        <div>
          <span className="font-semibold">Errors:</span>
          <ul>
            {_.map(dataImport.errors, (e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </div>
      )}

      {!client.isPendingInitialDataUpload && isAddingHouseholdData && (
        <div>
          <p className="font-semibold">Warning:</p>
          You are importing a dataset that contains household data. This client&apos;s existing
          dataset does not contain household data. Importing mixed data will prevent running
          household reports.
        </div>
      )}

      {!client.isPendingInitialDataUpload && isMissingHouseholdData && (
        <div>
          <p className="font-semibold">Warning:</p>
          You are importing a dataset that does not contain household data. This client&apos;s
          existing dataset does contain household data. Importing mixed data will prevent running
          household reports.
        </div>
      )}

      <div>
        <span className="font-semibold">Summary:</span>
        <ul>
          {dataImport?.tabsProcessed && (
            <li>
              <span className="font-medium">Sheets Processed:</span>{' '}
              {_.join(dataImport.tabsProcessed, ', ')}
            </li>
          )}
          <li>
            <span className="font-medium">New Accounts:</span> {totalNewAccounts}
          </li>
          <li>
            <span className="font-medium">New Composites:</span> {totalNewComposites}
          </li>
          <li>
            <span className="font-medium">Changes to Inclusion:</span> {totalChangesToInclusion}
          </li>
          <li>
            <span className="font-medium">AUM Rows Processed:</span>{' '}
            {dataImport.aumDataChanges?.processedRows}
          </li>
          <li>
            <span className="font-medium">Benchmark Rows Processed:</span>{' '}
            {dataImport.benchmarkDataChanges?.processedRows}
          </li>
          <li>
            <span className="font-medium">Rules Rows Processed:</span>{' '}
            {dataImport.rulesChanges?.processedRows}
          </li>
          <li>
            <span className="font-medium">Disclosure Rows Processed:</span>{' '}
            {dataImport.disclosureChanges?.processedRows}
          </li>
        </ul>
      </div>

      {dataImport.portfolioDataChanges === null ? (
        renderLoading('Data changes are being computed...')
      ) : (
        <div className="flex size-full flex-col py-4">
          {_.map(_.keys(dataImport.portfolioDataChanges), (period, i) => {
            const data = dataImport.portfolioDataChanges[period]
            return (
              <Disclosure
                key={period}
                as="div"
                className={mergeClassNames(
                  'h-auto w-full flex-none flex-col overflow-hidden border-x-[1.5px] border-blue-800/40',
                  i === 0 && 'rounded-t-xl border-t-[1.5px]',
                  i > 0 && 'border-t-[1.5px]',
                  i === _.keys(dataImport.portfolioDataChanges).length - 1 &&
                    'rounded-b-xl border-b-[1.5px]',
                )}
                defaultOpen={false}
              >
                <DisclosureButton
                  className={mergeClassNames(
                    'group flex w-full flex-row items-center justify-between bg-blue-900/10 p-2',
                    data.action === 'no change' && 'bg-gray-800/5',
                  )}
                  disabled={data.action === 'no change'}
                >
                  <span
                    className={mergeClassNames(
                      'font-semibold text-blue group-data-[hover]:text-blue-dark group-data-[open]:text-blue-dark',
                      data.action === 'no change' && 'text-gray-500',
                    )}
                  >
                    Data {configureAction(data)} for{' '}
                    {dayjs(period, 'YYYYMMDD').format('MM/DD/YYYY')}
                  </span>

                  {data.action !== 'no change' && (
                    <>
                      <ChevronDownIcon className="block size-6 fill-blue group-data-[open]:hidden group-data-[hover]:fill-blue-dark" />
                      <ChevronUpIcon className="ml-2 hidden size-6 fill-blue group-data-[open]:block group-data-[hover]:fill-blue-dark" />
                    </>
                  )}
                </DisclosureButton>

                <DisclosurePanel
                  className="origin-top flex-col space-y-2 p-2 transition duration-100 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
                  transition
                >
                  {data.newAccounts.length > 0 &&
                    renderDataChangeBlock('New Accounts', data.newAccounts)}

                  {data.newComposites.length > 0 &&
                    renderDataChangeBlock('New Composites', data.newComposites)}

                  {data.updatedAccounts.length > 0 &&
                    renderDataChangeBlock('Updated Accounts', data.updatedAccounts)}

                  {data.closedAccounts.length > 0 &&
                    renderDataChangeBlock('Closed Accounts', data.closedAccounts)}

                  {data.accountsIsIncludedChanged.length > 0 &&
                    renderDataChangeBlock(
                      'Accounts with Changes to Inclusion',
                      data.accountsIsIncludedChanged,
                    )}
                </DisclosurePanel>
              </Disclosure>
            )
          })}
        </div>
      )}
    </div>
  )
}

ImportDataChanges.propTypes = {
  client: PropTypes.object.isRequired,
  dataImport: PropTypes.object.isRequired,
  resetPage: PropTypes.func,
}

export default ImportDataChanges
