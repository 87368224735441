import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'

// Components
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'

// Stores
import { TaskStoreContext } from '../../stores/TaskStore'

// Utils & Services
import { toast } from '../../utils/helpers'
import { createGlobalBenchmarkImport } from '../../services/global.service'

const UploadBenchmarkImportModal = ({ closeModal }) => {
  // Context
  const { addTask } = useContext(TaskStoreContext)

  // State
  const [loading, setLoading] = useState(false)

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  return (
    <Modal
      icon={<CloudArrowUpIcon className="h-6 stroke-white" />}
      open
      title="Upload Global Benchmark File"
      loading={loading}
      onClose={closeModal}
      content={
        <div className="mt-8 flex size-full flex-row pt-2">
          <div className="w-full">
            <FileUploader
              acceptedFileTypes={[
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ]}
              handleUploadToServer={async (file) => {
                const response = await createGlobalBenchmarkImport(
                  {
                    file: file.file,
                    originalFilename: file.name,
                  },
                  handleError,
                  setLoading,
                  () => {},
                )

                if (response) {
                  addTask({ type: 'import-benchmarks', id: response.id, data: response })
                  handleSuccess('Global Benchmark Import created.')
                  closeModal()
                }
              }}
              id="file"
              maxFiles={1}
              type="manual"
            />
          </div>
        </div>
      }
      actions={[{ type: 'cancel', label: 'Cancel', onClick: closeModal }]}
    />
  )
}

UploadBenchmarkImportModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default UploadBenchmarkImportModal
