import { forEach, isEmpty, isPlainObject, keys, map, some } from 'lodash'
import React from 'react'
import { toast as displayToast } from 'react-hot-toast'
import { useImmer } from 'use-immer'

// Component
import { Alert } from '../components/Alert'
import {
  oneLowercaseLetterRegEx,
  oneNumberRegEx,
  oneSymbolRegEx,
  oneUppercaseLetterRegEx,
} from '../components/PasswordVerifier'

const ERROR_DEFAULT = 'An error occurred. Please try again.'
const ERROR_NO_INTERNET = 'The server could not be reached. Please try again.'
const ERROR_TIMEOUT = 'Request timed out. Please try again.'

export const createWithDoc = ({ envName = '', docFunction = () => {}, component = '' }) => {
  let createComponentWithDoc
  if (envName !== 'production') {
    createComponentWithDoc = docFunction(component) // eslint-disable-line global-require
  }
  return createComponentWithDoc || component
}

export const getErrorMessage = (err) => {
  // - Axios does not return a response object if the service cannot be reached
  if (err.code === 'ERR_NETWORK') {
    return ERROR_NO_INTERNET
  }

  if (err.code === 'ECONNABORTED' || err.code === 'ETIMEDOUT') {
    return ERROR_TIMEOUT
  }

  // - If we do have a response and data object, use the full error object
  if (err.response && err.response.data) {
    try {
      const errorStringOrObject = Object.values(err.response.data)[0]
      if (isPlainObject(errorStringOrObject)) return errorStringOrObject[0]
      return errorStringOrObject
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Could not infer error message from error response object')
    }
  }

  // If a message has not been set, fallback to our default message
  return ERROR_DEFAULT
}

/**
 * Updates the state object with the updated field.
 * @param {object} state
 * @param {object} updatedField
 */
export const updateState = (state, updatedField) => ({
  ...state,
  ...updatedField,
})

/**
 * Updates an array stored in state.
 * - `add`: adds value to the array
 * - `remote`: removes the value at the index from the array
 * - `update`: updates the value at the index in the array
 * - `reset` and `filter`: returns the supplied value
 * - `clear`: removes all values from the array
 * @param {object} state
 * @param {object} updatedState
 */
export const updateArrayState = (state, { type, value, index }) => {
  switch (type) {
    case 'add':
      return [...state, value]
    case 'remove': {
      const updatedState = [...state]
      updatedState.splice(index, 1)
      return updatedState
    }
    case 'update': {
      const updatedState = [...state]
      updatedState[index] = value
      return updatedState
    }
    case 'reset':
    case 'filter':
      return value
    case 'clear':
      return []
    default:
      return state
  }
}

export const joinClassNames = (...classes) => classes.filter(Boolean).join(' ')

export const useImmerState = useImmer

export const toast = (message, type) =>
  displayToast.custom(
    (t) =>
      t.visible && (
        <Alert message={message} type={type} onClose={() => displayToast.dismiss(t.id)} />
      ),
    { id: message },
  )

/**
 * Handles updating pagination based on the supplied parameters and functions.
 * @param {number} page
 * @param {number} currentPage
 * @param {number} perPage
 * @param {number} totalRows
 * @param {object} pages
 * @param {func} setCurrentPage
 * @param {func} request
 * @param {string} baseUrl
 * @param {string} filter
 */
export const handlePagination = async (
  page,
  currentPage,
  perPage,
  totalRows,
  pages,
  setCurrentPage,
  request,
  baseUrl,
  filter = null,
) => {
  // If the user is requesting the first page and we are not on the next page,
  // we need to get the very first page and not utilize `previous`.
  if (page === 1 && currentPage > 1) {
    await request(`${baseUrl}${perPage}&${filter ? `${filter}&` : ''}page=1`)
  }
  // If the user is requesting the last page and we are not on the previous page,
  // we need to get the very last page and not utilize `next`.
  else if (page > currentPage && page - currentPage > 1) {
    await request(
      `${baseUrl}${perPage}&${filter ? `${filter}&` : ''}page=${Math.ceil(totalRows / perPage)}`,
    )
  }
  // If the user is requesting the next page.
  else if (page > currentPage) {
    await request(pages.next)
  }
  // Otherwise, the user is requesting the previous page.
  else {
    await request(pages.previous)
  }

  setCurrentPage(page)
}

/**
 * Handles verifying the password against requirements.
 * @param {string} value
 * @returns {string} error if there is one to display
 */
export const verifyPassword = (value) => {
  if (!value || value.length < 8) return 'Please enter more than 8 characters'
  if (!oneUppercaseLetterRegEx.test(value)) return 'Please enter at least 1 uppercase letter'
  if (!oneLowercaseLetterRegEx.test(value)) return 'Please enter at least 1 lowercase letter'
  if (!oneNumberRegEx.test(value)) return 'Please enter at least 1 number'
  if (!oneSymbolRegEx.test(value)) return 'Please enter at least 1 symbol'
  return undefined
}

/**
 * Handles inserting either an object or an array into another array at a specific index.
 * @param {array} array
 * @param {number} index
 * @param {object|array} item
 */
export const insert = (array, index, item) => {
  // If item is another array, insert each element individually
  if (Array.isArray(item)) {
    let i = index
    item.forEach((element) => {
      array.splice(i, 0, element)
      i += 1
    })
  } else {
    array.splice(index, 0, item)
  }

  return array
}

export const hasAnyFilterSelected = (filters) =>
  some(filters, (filter) => Array.isArray(filter.value) && !isEmpty(filter.value))

// Generate query string based on updated filter structure
export const configureFilterQuery = (filterQuery) =>
  map(filterQuery, (filter, key) => {
    // Check if `filter.value` exists and has items, else skip
    if (!filter || !Array.isArray(filter.value) || filter.value.length === 0) return null

    // For each value in `filter.value`, join them with `&key=` format
    return filter.value.map((value) => `${key}=${encodeURIComponent(value)}`).join('&')
  })
    .filter((x) => x !== null) // Remove any null entries
    .join('&')

/**
 * Configures a filter object based on the provided search parameters and default filters.
 * @param {object} searchParams
 * @param {object} filters
 * @returns filter object
 */
export const configureFiltersFromSearchParams = (searchParams, filters) => {
  const params = Object.fromEntries(searchParams)
  const updatedFilters = { ...filters }

  forEach(keys(params), (key) => {
    updatedFilters[key] = { value: params[key].split(',') }
  })

  return updatedFilters
}
