import React from 'react'
import { AdjustmentsHorizontalIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, UserIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import _ from 'lodash'

// Components
import { Button } from '../../components/Button'

// Service
import { updatePortfolioDataImport } from '../../services/portfolio.service'

const ImportDetails = ({
  activeImport,
  fileName,
  handleCancelSuccess,
  handleErrors,
  setLoadingCancel,
}) => {
  const canCancel =
    activeImport?.status !== 'Imported' &&
    activeImport?.status !== 'Failed' &&
    activeImport?.status !== 'Canceled'

  const overridingData =
    activeImport?.overrideExistingData || activeImport?.periodOverrides?.length > 0

  /**
   * Renders the status of the active import.
   */
  const renderStatus = () => {
    let color = 'text-green-700 ring-green-600/20 bg-green-50'

    if (
      activeImport?.status === 'Pending' ||
      activeImport?.status === 'Processing' ||
      activeImport?.status === 'Importing'
    ) {
      color = 'text-yellow-700 ring-yellow-600/20 bg-yellow-50'
    }
    if (
      activeImport?.status === 'Ready for Review' ||
      activeImport?.status === 'Ready for Initial Review'
    ) {
      color = 'text-blue-700 ring-blue-600/20 bg-blue-50'
    }
    if (activeImport?.status === 'Failed' || activeImport?.status === 'Canceled') {
      color = 'text-red-700 ring-red-600/20 bg-red-50'
    }

    return (
      <dd
        className={mergeClassNames(
          'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
          color,
        )}
      >
        {activeImport?.status}
      </dd>
    )
  }

  return (
    <div className="w-full items-center px-4 pt-2 sm:col-span-1">
      <h2 className="sr-only">File Metadata</h2>
      <div className="w-full rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap pb-2">
          <div className="flex-auto overflow-hidden px-2 pt-2 md:px-3 md:pt-2">
            <div className="flex flex-col items-start justify-between gap-2 lg:flex-row lg:items-center">
              <dt className="text-sm font-semibold text-gray-900">Data Import</dt>

              <div className="flex-none">
                <dt className="sr-only">Status</dt>
                {renderStatus()}
              </div>
            </div>

            <dd className="mt-1 truncate text-base font-semibold text-gray-900">
              {fileName.split('/').pop().replace(/_/g, ' ')}
            </dd>
          </div>

          <div className="flex w-full flex-none flex-col items-start border-t border-gray-900/5 px-2 pt-2 lg:mt-4 lg:flex-row lg:items-center lg:gap-x-2 lg:px-4">
            <dt className="flex flex-row items-center">
              <span className="sr-only">Created At</span>
              <CalendarDaysIcon aria-hidden="true" className="mr-2 h-6 w-5 text-gray-400" />
              <span className="text-sm text-gray-500">Created:</span>
            </dt>

            <dd className="text-sm font-semibold text-gray-500">
              {dayjs(activeImport?.createdAt).format('MMM DD, YYYY h:mm A')}
            </dd>
          </div>

          <div className="mt-2 flex w-full flex-none flex-col items-start px-2 lg:mt-4 lg:flex-row lg:items-center lg:gap-x-2 lg:px-4">
            <dt className="flex flex-row items-center">
              <span className="sr-only">Last Updated At</span>
              <CalendarDaysIcon aria-hidden="true" className="mr-2 h-6 w-5 text-gray-400" />
              <span className="text-sm text-gray-500">Last Updated:</span>
            </dt>

            <dd className="text-sm font-semibold text-gray-500">
              {dayjs(activeImport?.modifiedAt).format('MMM DD, YYYY h:mm A')}
            </dd>
          </div>

          <div className="mt-2 flex w-full flex-none flex-col items-start px-2 lg:mt-4 lg:flex-row lg:items-center lg:gap-x-2 lg:px-4">
            <dt className="flex flex-row items-center">
              <span className="sr-only">Created By</span>
              <UserIcon aria-hidden="true" className="mr-2 h-6 w-5 text-gray-400" />
              <span className="text-sm text-gray-500">Created By:</span>
            </dt>
            <dd className="text-sm font-semibold text-gray-500">
              {activeImport?.createdBy?.firstName} {activeImport?.createdBy?.lastName}
            </dd>
          </div>

          <div className="mt-2 flex w-full flex-none flex-col items-start px-2 lg:mt-4 lg:flex-row lg:items-center lg:gap-x-2 lg:px-4">
            <dt className="flex flex-row items-center">
              <span className="sr-only">Updated By</span>
              <UserIcon aria-hidden="true" className="mr-2 h-6 w-5 text-gray-400" />
              <span className="text-sm text-gray-500">Updated By:</span>
            </dt>
            <dd className="text-sm font-semibold text-gray-500">
              {activeImport?.lastUpdatedBy?.firstName} {activeImport?.lastUpdatedBy?.lastName}
            </dd>
          </div>

          {overridingData && (
            <div className="mt-2 flex w-full flex-none flex-col items-start px-2 lg:mt-4 lg:flex-row lg:items-center lg:gap-x-2 lg:px-4">
              <dt className="flex flex-row items-center">
                <span className="sr-only">Updated By</span>
                <AdjustmentsHorizontalIcon
                  aria-hidden="true"
                  className="mr-2 h-6 w-5 text-gray-400"
                />
                <span className="text-sm text-gray-500">Options:</span>
              </dt>
              <dd className="text-sm font-semibold text-gray-500">
                {activeImport?.overrideExistingData
                  ? 'Overriding All Data'
                  : `Overriding Periods: ${_.join(activeImport?.periodOverrides, ', ')}`}
              </dd>
            </div>
          )}

          {activeImport?.isHouseholdDataImport && (
            <div className="mt-2 flex w-full flex-none flex-col items-start px-2 lg:mt-4 lg:flex-row lg:items-center lg:gap-x-1 lg:px-4">
              <dt className="flex flex-row items-center">
                <span className="sr-only">Household Data Import</span>
                <CheckCircleIcon aria-hidden="true" className="mr-2 h-6 w-5 text-gray-400" />
                <dd className="text-sm font-semibold text-gray-500">Household Data Import</dd>
              </dt>
            </div>
          )}
        </dl>

        {canCancel && (
          <div className="flex justify-center border-t border-gray-900/5 p-2 md:mt-4 md:p-4">
            <Button
              background="bg-red"
              label="Cancel"
              onClick={() =>
                updatePortfolioDataImport(
                  activeImport.client.id,
                  activeImport.id,
                  { markAsCanceled: true },
                  handleErrors,
                  setLoadingCancel,
                  handleCancelSuccess,
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

ImportDetails.propTypes = {
  activeImport: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
  handleCancelSuccess: PropTypes.func.isRequired,
  handleErrors: PropTypes.func.isRequired,
  setLoadingCancel: PropTypes.func.isRequired,
}

export default ImportDetails
