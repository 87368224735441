import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { RadioButton } from 'primereact/radiobutton'

const DataTableRadioGroup = ({ value, handleChange, options }) => (
  <div className="flex flex-col gap-3">
    {_.map(options, (o) => (
      <div className="align-items-center flex" key={o.value}>
        <RadioButton
          inputId={o.value}
          value={o.label}
          onChange={(e) => handleChange(e.value)}
          checked={value === o.value}
        />
        <label htmlFor={o.value} className="ml-3">
          {o.label}
        </label>
      </div>
    ))}
  </div>
)

export default DataTableRadioGroup

DataTableRadioGroup.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
}
