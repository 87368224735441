/* eslint-disable indent */
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { BoltIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import dayjs from 'dayjs'

// Components
import { StatusEditor, TextAreaEditor, renderStatusTag } from '../CustomEditor'
import { DataTable } from '../DataTable'
import { Modal } from '../Modal'

// Utils & Services
import { approveRulesCheck, getRulesCheckRun } from '../../services/portfolio.service'
import { toast } from '../../utils/helpers'
import { formatCurrency } from '../../utils/formatters'

const RulesCheckModal = ({ client, closeModal, rulesCheckId, updateTask }) => {
  // State
  const [loading, setLoading] = useState(false)
  const [loadingApproval, setLoadingApproval] = useState(false)
  const [violations, setViolations] = useState([])

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  const dataTable = useRef()

  useEffect(() => {
    const getUpdatedData = async () => {
      const response = await getRulesCheckRun(
        client.id,
        rulesCheckId,
        () => {},
        handleErrors,
        setLoading,
      )

      if (response) {
        setViolations(response.portfolioData)
      }
    }

    getUpdatedData()
  }, [rulesCheckId])

  /**
   * Handles row edit completion by updating the stored violations.
   * @param {object} event
   */
  const onRowEditComplete = async ({ newData }) => {
    const updatedViolations = [...violations]
    const index = updatedViolations.findIndex((v) => v.id === newData.id)
    updatedViolations[index] = newData

    setViolations(updatedViolations)
  }

  const actions =
    !rulesCheckId || violations?.length === 0
      ? [{ type: 'cancel', label: 'Close', onClick: closeModal }]
      : [
          { type: 'cancel', label: 'Cancel', onClick: closeModal },
          {
            type: 'submit',
            label: 'Save Updated Comments & Inclusions',
            onClick: async () => {
              const response = await approveRulesCheck(
                client.id,
                rulesCheckId,
                { markAsApproved: true, portfolioData: violations },
                () => {},
                handleErrors,
                setLoadingApproval,
              )

              if (response) {
                updateTask({ type: 'rules-check', id: response.id, data: response })
                handleSuccess('SCF and Mins Check approved.')
                closeModal()
              }
            },
          },
        ]

  const columns = useMemo(() => {
    const DEFAULT_COLUMNS = [
      {
        field: 'composite.id',
        header: 'Composite',
        body: (rowData) => rowData.composite.name,
        style: { minWidth: '250px' },
      },
      {
        field: 'account.id',
        header: 'Account',
        body: (rowData) => `${rowData.account.name} (${rowData.account.number})`,
        style: { minWidth: '230px' },
      },
      {
        field: 'period',
        header: 'Period',
        body: (row) => dayjs(row.period).format('MM/DD/YYYY'),
        style: { minWidth: '150px' },
      },
      {
        field: 'beginningValue',
        header: 'Beginning Value',
        body: (rowData) => formatCurrency(rowData.beginningValue),
        style: { minWidth: '200px' },
      },
      {
        field: 'endingValue',
        header: 'Ending Value',
        body: (rowData) => formatCurrency(rowData.endingValue),
        style: { minWidth: '200px' },
      },
      {
        field: 'grossReturn',
        header: 'Gross Return',
        body: (rowData) =>
          rowData.grossReturn !== null ? `${(rowData.grossReturn * 100).toFixed(2)}%` : '-',
        style: { minWidth: '180px' },
      },
      {
        field: 'netReturn',
        header: 'Net Return',
        body: (rowData) =>
          rowData.netReturn !== null ? `${(rowData.netReturn * 100).toFixed(2)}%` : '-',
        style: { minWidth: '180px' },
      },
      {
        field: 'isIncluded',
        header: 'Included',
        editor: StatusEditor,
        body: (rowData) => renderStatusTag(rowData.isIncluded),
        style: { minWidth: '160px' },
      },
      {
        field: 'isFeePaying',
        header: 'Fee Paying',
        body: (rowData) => renderStatusTag(rowData.isFeePaying),
        style: { minWidth: '160px' },
      },
      {
        field: 'hasBundledFee',
        header: 'Bundled Fee',
        body: (rowData) => renderStatusTag(rowData.hasBundledFee),
        style: { minWidth: '160px' },
      },
      {
        field: 'comments',
        header: 'Comments',
        editor: TextAreaEditor,
        style: { minWidth: '300px' },
      },
    ]

    let updatedColumns = DEFAULT_COLUMNS

    // Add columns for Portfolios to the second and third column if household data is available
    if (client?.hasAnyHouseholdData) {
      const HOUSEHOLD_COLUMNS = _.cloneDeep(DEFAULT_COLUMNS)
      HOUSEHOLD_COLUMNS.splice(1, 0, {
        field: 'portfolio.id',
        header: 'Portfolio',
        body: (rowData) => `${rowData.portfolio?.name} (${rowData.portfolio?.number})`,
        style: { minWidth: '230px' },
      })

      updatedColumns = HOUSEHOLD_COLUMNS
    }

    return updatedColumns
  }, [])

  const renderRulesResults = () => {
    if (!rulesCheckId || violations?.length === 0) {
      return <span className="text-center text-sm">No rule violations.</span>
    }

    return (
      <div className="h-[450px] w-full">
        <DataTable
          columns={columns}
          containerClassName="h-full"
          data={violations}
          emptyMessage="No rule violations."
          onRowEditComplete={onRowEditComplete}
          areRowsEditable
          ref={dataTable}
          scrollHeight="448px"
        />
      </div>
    )
  }

  return (
    <Modal
      className="sm:max-h-[90vh] sm:w-[90vw] sm:max-w-screen-xl"
      icon={<BoltIcon className="h-6 fill-white stroke-white" />}
      open
      title="SCF and Mins Check"
      description="Review and approve the check results. Data is automatically excluded when a violation is found – please click Approve to store the updated comments and inclusions, or first make edits manually below."
      loading={loadingApproval}
      onClose={closeModal}
      content={
        <div className="flex size-full flex-col gap-4 pt-2">
          {loading ? (
            <div className="flex size-full flex-col items-center justify-center space-y-2 py-2">
              <span className="text-lg font-bold">Loading...</span>

              <span className="flex items-center pr-3">
                <div className="size-8">
                  {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
                  <svg className="size-8 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                    <ArrowPathIcon className="size-8" aria-hidden="true" />
                  </svg>
                </div>
              </span>
            </div>
          ) : (
            <div className="flex justify-center">{renderRulesResults()}</div>
          )}
        </div>
      }
      actions={actions}
    />
  )
}

RulesCheckModal.propTypes = {
  client: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  rulesCheckId: PropTypes.string.isRequired,
  updateTask: PropTypes.func.isRequired,
}

export default RulesCheckModal
