import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom'

// Pages
import { ClientActivity } from '../ClientActivity'
import { ClientAumData } from '../ClientAumData'
import { ClientBenchmarkData } from '../ClientBenchmarkData'
import { ClientDashboard } from '../ClientDashboard'
import { ClientImports } from '../ClientImports'
import { Benchmarks, ClientSettings, Composites, Disclosures, Rules } from '../ClientSettings'
import { Clients } from '../Clients'
import { GlobalBenchmarks } from '../GlobalBenchmarks'
import { GlobalRules } from '../GlobalRules'
import { Login } from '../Login'
import { MultiFactorAuthentication } from '../MultiFactorAuthentication'
import { NotFound } from '../NotFound'
import { PasswordReset } from '../PasswordReset'
import { PasswordResetRequest } from '../PasswordResetRequest'
import { ClientPortfolioData } from '../ClientPortfolioData'
import { ClientPortfolioDataImport } from '../ClientPortfolioDataImport'
import { Report } from '../Report'
import { UserProfile } from '../UserProfile'
import { Users } from '../Users'

// Components
import { NavBar } from '../../components/NavBar'
import { PrivateRoute } from '../../components/PrivateRoute'
import { PublicRoute } from '../../components/PublicRoute'
import { TaskProgressList } from '../../components/TaskProgressList'

// Store
import { UserStoreContext } from '../../stores/UserStore'

/**
 *
 * Routes
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
const PageRoutes = observer(() => {
  // Context
  const { isAuthenticated } = useContext(UserStoreContext)

  return (
    <div className="h-screen w-screen">
      <Helmet
        titleTemplate="%s - Longs Peak Advisory Portal"
        defaultTitle="Longs Peak Advisory Portal"
      >
        <meta name="description" content="Longs Peak Advisory Portal" />
      </Helmet>

      <NavBar>
        <Routes>
          {/* Main Routes */}
          <Route path="*" element={<NotFound />} />

          {/* Public Routes */}
          <Route element={<PublicRoute component={Login} />} caseSensitive path="/" />
          <Route element={<PublicRoute component={Login} />} caseSensitive path="/login" />
          <Route
            element={<PublicRoute component={MultiFactorAuthentication} />}
            caseSensitive
            path="/verification"
          />
          {/* <Route element={<PublicRoute component={SignUp} />} caseSensitive path="/sign-up" /> */}
          <Route
            element={<PublicRoute component={PasswordReset} />}
            caseSensitive
            path="/password-reset"
          />
          <Route
            element={<PublicRoute component={PasswordReset} />}
            caseSensitive
            path="/set-password"
          />
          <Route
            element={<PublicRoute component={PasswordResetRequest} />}
            caseSensitive
            path="/password-reset-request"
          />

          {/* Private Routes */}
          <Route element={<PrivateRoute component={Clients} />} caseSensitive path="/clients" />
          <Route
            element={<PrivateRoute component={GlobalBenchmarks} />}
            caseSensitive
            path="/global-benchmarks"
          />
          <Route
            element={<PrivateRoute component={GlobalRules} />}
            caseSensitive
            path="/global-rules"
          />
          <Route
            element={<PrivateRoute component={Users} restrictedForLimitedUsers />}
            caseSensitive
            path="/users"
          />

          {/* Client Routes */}
          <Route
            element={<PrivateRoute component={ClientDashboard} />}
            caseSensitive
            path="/clients/:clientId/dashboard"
          />
          <Route
            element={<PrivateRoute component={ClientActivity} />}
            caseSensitive
            path="/clients/:clientId/dashboard/activity-log"
          />
          <Route
            element={<PrivateRoute component={ClientImports} />}
            caseSensitive
            path="/clients/:clientId/imports"
          />
          <Route
            element={<PrivateRoute component={ClientPortfolioDataImport} />}
            caseSensitive
            path="/clients/:clientId/imports/new"
          />
          <Route
            element={<PrivateRoute component={Report} />}
            caseSensitive
            path="/clients/:clientId/dashboard/new-report"
          />
          <Route
            element={<PrivateRoute component={Report} />}
            caseSensitive
            path="/clients/:clientId/dashboard/reports/:reportId"
          />
          <Route
            element={<PrivateRoute component={ClientPortfolioData} />}
            caseSensitive
            path="/clients/:clientId/portfolio-data"
          />
          <Route
            element={<PrivateRoute component={ClientBenchmarkData} />}
            caseSensitive
            path="/clients/:clientId/benchmark-data"
          />
          <Route
            element={<PrivateRoute component={ClientAumData} />}
            caseSensitive
            path="/clients/:clientId/aum-data"
          />

          <Route
            element={<PrivateRoute component={ClientSettings} />}
            caseSensitive
            path="/clients/:clientId/settings"
          >
            <Route
              element={<Benchmarks />}
              caseSensitive
              path="/clients/:clientId/settings/benchmarks"
            />

            <Route
              element={<Composites />}
              caseSensitive
              path="/clients/:clientId/settings/composites"
            />

            <Route
              element={<Disclosures />}
              caseSensitive
              path="/clients/:clientId/settings/disclosures"
            />

            <Route element={<Rules />} caseSensitive path="/clients/:clientId/settings/rules" />
          </Route>

          {/* User Routes */}
          <Route
            element={<PrivateRoute component={UserProfile} />}
            caseSensitive
            path="/profile"
          />
        </Routes>
      </NavBar>

      {isAuthenticated && <TaskProgressList />}
    </div>
  )
})

export default PageRoutes
