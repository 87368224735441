import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import CurrencyInput from 'react-currency-input-field'

/**
 * Text Input
 */
const TextInput = forwardRef(
  (
    {
      autoComplete = 'off',
      className = '',
      containerClassName = null,
      currency = false,
      inputStyles = '',
      endIcon = null,
      error = '',
      fullWidth = false,
      icon = null,
      id = '',
      label = '',
      name = '',
      placeholder = '',
      subLabel = '',
      type = 'text',
      ...rest
    },
    ref,
  ) => (
    <div
      className={mergeClassNames(
        `flex flex-col place-items-start ${fullWidth ? 'w-full' : ''}`,
        containerClassName,
      )}
    >
      <label htmlFor={id} className="pb-1 text-sm font-medium text-gray-700">
        {label}
      </label>

      {subLabel && <p className="pb-2 text-xs italic text-gray-500">{subLabel}</p>}

      <div
        className={mergeClassNames(
          'relative flex w-full appearance-none rounded-md border-[1.5px] border-gray-200 bg-white px-3 py-2.5 text-sm text-gray-900 placeholder:text-gray-500 focus-within:border-blue',
          className,
        )}
      >
        {icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
            {icon}
          </div>
        )}

        {currency ? (
          <CurrencyInput
            id={id}
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            type={type}
            className={mergeClassNames(
              'text-primary w-full border-none bg-transparent p-0 text-sm placeholder:text-sm placeholder:font-normal placeholder:text-gray-600 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50',
              inputStyles,
            )}
            ref={ref}
            {...rest}
          />
        ) : (
          <input
            id={id}
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            type={type}
            className={mergeClassNames(
              'text-primary w-full border-none bg-transparent p-0 text-sm placeholder:text-sm placeholder:font-normal placeholder:text-gray-600 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50',
              inputStyles,
              icon ? 'pl-1' : 'pl-0',
            )}
            ref={ref}
            {...rest}
          />
        )}

        {endIcon && <div className="inset-y-0 right-0 flex items-center">{endIcon}</div>}
      </div>

      {error && (
        <div className="mt-1 w-full bg-transparent px-2 py-1 text-center">
          <p className="text-sm font-medium text-error" id={`error:${name}`}>
            {error}
          </p>
        </div>
      )}
    </div>
  ),
)

TextInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  currency: PropTypes.bool,
  inputStyles: PropTypes.string,
  endIcon: PropTypes.object,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  nunito: PropTypes.bool,
  placeholder: PropTypes.string,
  subLabel: PropTypes.string,
  type: PropTypes.string,
}

export default TextInput
