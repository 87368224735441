// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the disclosures for the specified `clientId`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientDisclosures = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new disclosure with the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createClientDisclosure = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.post(`/clients/${clientId}/disclosures/`, payload)
    setSuccess('New disclosure added.')
    setLoading(false)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Updates the disclosure with the specified `disclosureId` and `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` for the new disclosure
 */
export const updateClientDisclosure = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(`/clients/${clientId}/disclosures/${payload.id}/`, payload)
    setSuccess(`${response.firmName} updated.`)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the disclosure with the specified `disclosureId` and `payload`.
 * @param {string} clientId
 * @param {string} disclosureId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteClientDisclosure = async (
  clientId,
  disclosureId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/clients/${clientId}/disclosures/${disclosureId}/`)
    setLoading(false)
    setSuccess()
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}
