import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'

// Components
import { Button } from '../../components/Button'
import { ClientHeader } from '../../components/ClientHeader'
import { CustomLink } from '../../components/CustomLink'
import {
  DateEditor,
  PriceEditor,
  renderSelectEditor,
  renderMultiSelectFilter,
} from '../../components/CustomEditor'
import { DataTable, DEFAULT_FILTER_OPTIONS } from '../../components/DataTable'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'

// Utils
import { formatCurrency } from '../../utils/formatters'

// Services
import { getClient, getClientAumDataYears } from '../../services/clients.service'
import { deleteAumData, getAumData, updateAumData } from '../../services/aum.service'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

// Utils
import { configureFilterQuery, hasAnyFilterSelected, toast } from '../../utils/helpers'

// Hooks
import { usePagination, useSorting } from '../../hooks/DataTableManagement'
import AddAumDataModal from './AddAumDataModal'

const DEFAULT_FILTERS = {
  year: { value: [] },
}

const ClientAumData = observer(() => {
  // Context
  const { clientId } = useParams()
  const { canModifyData, isImportInProgress, client, setClient } = useContext(
    ClientDashboardStoreContext,
  )

  // Pagination
  const { pagination, setTotalRecords } = usePagination(50)
  const { perPage, currentPage } = pagination

  // Sorting
  const { sorting } = useSorting('year')
  const { sortedColumn } = sorting

  // State
  const [loadingClient, setLoadingClient] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const [years, setYears] = useState([])
  const [showAddRowModal, setShowAddRowModal] = useState(false)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const dataTable = useRef()

  const AUM_DATA_BASE_URL = `/clients/${clientId}/aum-data/`

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated list of aum data; updates pagination.
   * @param {string} url
   */
  const getUpdatedAumData = async (url) => {
    const response = await getAumData(url, handleErrors, setLoadingData)

    if (response) {
      setTotalRecords(response.count)
      setData(response.results)
    }
  }

  /**
   * When the filter, sort, current page, or row count changes, get the updated list of aum data.
   */
  useEffect(() => {
    if (clientId) {
      const queryFilters = configureFilterQuery({
        year: filters.year || DEFAULT_FILTERS.year,
      })

      getUpdatedAumData(
        `${AUM_DATA_BASE_URL}?order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}&${queryFilters}`,
      )
    }
  }, [perPage, clientId, sortedColumn, currentPage, filters])

  useEffect(() => {
    const getClientData = async () => {
      const updatedClient = await getClient(clientId, setError, setLoadingClient)
      if (updatedClient) {
        setClient(updatedClient)
      }

      const [clientAumYears] = await Promise.all([getClientAumDataYears(clientId, handleErrors)])

      if (clientAumYears)
        setYears(
          _.map(clientAumYears, (d) => ({
            label: d,
            value: d,
            id: d,
          })),
        )
    }

    getClientData()
  }, [clientId])

  /**
   * Handles row edit completion by updating the local `data` with new data and
   * submitting it to the backend. Converts `year` to ISO string if it's a Date object.
   * Sends the updated row to `updateAumData` and updates the UI on success.
   * @param {object} event
   */
  const onRowEditComplete = async ({ newData, index }) => {
    const oldAumData = [...data]
    const updatedData = [...data]

    if (newData.year instanceof Date) {
      const [date] = newData.year.toISOString().split('T')
      // eslint-disable-next-line no-param-reassign
      newData.year = date
    }

    // Optimistically update the data to prevent showing internal account values
    updatedData[index] = { ...newData }
    setData(updatedData)

    await updateAumData(
      clientId,
      newData.id,
      {
        year: newData.year,
        aumAmount: newData.aumAmount,
        advisoryOnlyAssetsAmount: newData.advisoryOnlyAssetsAmount,
        uncalledCommittedCapitalAmount: newData.uncalledCommittedCapitalAmount,
        assetsDisplayedIn: newData.assetsDisplayedIn,
      },
      (m) => {
        // Reset back to previous value
        setData(oldAumData)
        handleErrors(m)
      },
      () => {},
      (m) => handleSuccess(m),
    )
  }

  return (
    <PageContainer>
      <StateContainer error={error} loading={loadingClient}>
        <div className="size-full">
          <ClientHeader client={client} />

          <div className="flex h-[calc(100vh-150px)] w-full flex-col bg-white px-4 pb-12 pt-6 sm:px-6 lg:px-8">
            <div className="flex size-full flex-col space-y-6">
              <div className="flex w-full flex-col justify-between sm:flex-row sm:items-center">
                <div className="flex flex-row items-center gap-2">
                  <h3 className="text-xl font-semibold leading-6 text-gray-900">AUM Data</h3>
                </div>

                {client?.latestSuccessfulImport !== null && (
                  <div className="mt-3 flex flex-row gap-2 sm:ml-4 sm:mt-0">
                    {hasAnyFilterSelected(filters) && (
                      <Button
                        label="Clear Filters"
                        background="bg-gray"
                        onClick={() => {
                          setFilters(DEFAULT_FILTERS)
                          getUpdatedAumData(
                            `${AUM_DATA_BASE_URL}?order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                          )
                        }}
                      />
                    )}

                    {canModifyData && (
                      <Button label="Add Row" onClick={() => setShowAddRowModal(true)} />
                    )}

                    <CustomLink to={`/clients/${clientId}/imports/new`}>
                      {isImportInProgress ? 'View Import' : 'Import Data'}
                    </CustomLink>
                  </div>
                )}
              </div>

              <DataTable
                data={data}
                columns={[
                  {
                    field: 'year',
                    header: 'Year',
                    sortable: true,
                    body: (row) => dayjs(row.year).format('MM/DD/YYYY'),
                    editor: DateEditor,
                    style: { minWidth: '150px' },
                    filterField: 'year',
                    ...DEFAULT_FILTER_OPTIONS,
                    filterElement: (options) =>
                      renderMultiSelectFilter(
                        years,
                        (selected) => {
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            year: {
                              value: selected,
                            },
                          }))
                        },
                        'Filter by Year',
                        options,
                      ),
                  },
                  {
                    field: 'aumAmount',
                    header: 'AUM Amount',
                    sortable: true,
                    editor: PriceEditor,
                    body: (rowData) => formatCurrency(rowData.aumAmount),
                    style: { minWidth: '220px' },
                  },
                  {
                    field: 'advisoryOnlyAssetsAmount',
                    header: 'Advisory Only Assets Amount',
                    sortable: true,
                    editor: PriceEditor,
                    body: (rowData) => formatCurrency(rowData.advisoryOnlyAssetsAmount),
                    style: { minWidth: '220px' },
                  },
                  {
                    field: 'uncalledCommittedCapitalAmount',
                    header: 'Uncalled Committed Capital Amount',
                    sortable: true,
                    editor: PriceEditor,
                    body: (rowData) => formatCurrency(rowData.uncalledCommittedCapitalAmount),
                    style: { minWidth: '220px' },
                  },
                  {
                    field: 'assetsDisplayedIn',
                    header: 'Assets Displayed In',
                    sortable: true,
                    body: (rowData) => rowData.assetsDisplayedIn,
                    style: { minWidth: '200px' },
                    editor: (options) =>
                      renderSelectEditor(
                        [
                          { label: 'Thousands', value: 'Thousands' },
                          { label: 'Millions', value: 'Millions' },
                          { label: 'Billions', value: 'Billions' },
                        ],
                        options.rowData.assetsDisplayedIn,
                        'Select a Display',
                        options,
                      ),
                  },
                ]}
                loading={loadingData}
                rowsPerPageOptions={[50, 100, 500, 1000]}
                pagination={pagination}
                sorting={sorting}
                loadingDelete={loadingDelete}
                onRowEditComplete={onRowEditComplete}
                onRowDeleteConfirm={(row) => {
                  deleteAumData(clientId, row.id, handleErrors, setLoadingDelete, () => {
                    handleSuccess('AUM data row deleted.')
                    getUpdatedAumData(
                      `${AUM_DATA_BASE_URL}?order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                    )
                  })
                }}
                areRowsDeleteable={canModifyData}
                areRowsEditable={canModifyData}
                hoverEffect={false}
                rowDeleteConfirm={(row) => ({
                  title: `Delete AUM Data Row`,
                  type: 'warning',
                  message: (
                    <div className="flex flex-col">
                      <span className="">Are you sure you want to delete the row for:</span>
                      <div className="mt-1.5 flex gap-1">
                        Year:
                        <span className="font-semibold">{row.year}</span>
                      </div>
                    </div>
                  ),
                })}
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                ref={dataTable}
              />
            </div>
          </div>
        </div>
      </StateContainer>

      {showAddRowModal && (
        <AddAumDataModal
          client={client}
          closeModal={() => setShowAddRowModal(false)}
          onSuccess={() => {
            setShowAddRowModal(false)
            getUpdatedAumData(
              `${AUM_DATA_BASE_URL}?order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
            )
          }}
        />
      )}
    </PageContainer>
  )
})

export default ClientAumData
