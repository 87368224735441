/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import { Column } from 'primereact/column'
import { DataTable as Table } from 'primereact/datatable'
import PropTypes from 'prop-types'
import React, { forwardRef, useState } from 'react'
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/solid'
import { twMerge as mergeClassName } from 'tailwind-merge'

// Components
import { Button } from '../Button'

const DataTable = forwardRef(
  (
    {
      data = [],
      containerClassName = null,
      columns = [],
      emptyMessage = 'No entries to show',
      loading = false,
      loadingDelete = false,
      pagination = null,
      sorting = null,
      onRowEditComplete = null,
      onRowDeleteConfirm = null,
      rowDeleteConfirm = null,
      rowsPerPageOptions = [10, 15, 20, 30, 50],
      areRowsDeleteable = false,
      areRowsEditable = false,
      onRowClick = () => {},
      hoverEffect = true,
      filters = null,
      onFilter = () => {},
      rowExpansionTemplate = null,
      scrollHeight = '650px',
    },
    ref,
  ) => {
    // State
    const [expandedRows, setExpandedRows] = useState([])

    return (
      <div className={mergeClassName('w-full overflow-x-auto', containerClassName)}>
        <Table
          lazy
          value={data}
          emptyMessage={emptyMessage}
          paginator={!!pagination}
          className="overflow-auto rounded-lg border p-4 shadow-sm"
          rowsPerPageOptions={rowsPerPageOptions}
          rows={pagination?.perPage}
          page={pagination?.currentPage}
          totalRecords={pagination?.totalRecords}
          first={pagination?.first}
          onPage={pagination?.handlePageChange}
          onSort={sorting?.handleSortChange}
          sortOrder={sorting?.sortOrder}
          sortField={sorting?.sortField}
          editMode="row"
          scrollable
          scrollHeight={scrollHeight}
          onRowEditComplete={onRowEditComplete}
          dataKey="id"
          loading={loading}
          rowClassName={() => `${hoverEffect ? 'hover:bg-blue-100 hover:cursor-pointer' : ''}`}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          pt={{
            paginator: {
              RPPDropdown: {
                root: 'border-[.5px] border-gray-dark hover:border-blue rounded-md',
              },
            },
          }}
          onRowClick={onRowClick}
          onFilter={onFilter}
          filters={filters}
          filterIcon={({ props: { filters: selectedFilters, column } }) => {
            const active = selectedFilters[column.props.filterField]?.value?.length > 0
            return (
              <FunnelIcon
                className={mergeClassName(
                  'w-5 fill-blue stroke-white hover:stroke-2',
                  active && 'fill-white',
                )}
              />
            )
          }}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={rowExpansionTemplate && expandedRows}
          ref={ref}
        >
          {rowExpansionTemplate && <Column expander style={{ width: '3em' }} />}

          {columns.map((col) => (
            <Column
              key={col.field}
              {...col}
              {...(!filters && { filter: false })}
              {...(!sorting && { sortable: false })}
            />
          ))}

          {areRowsDeleteable && rowDeleteConfirm && onRowDeleteConfirm && (
            <Column
              alignHeader="center"
              align="center"
              body={(row) => (
                <Button
                  className="flex items-center justify-center self-center"
                  loading={loadingDelete}
                  onClick={() => onRowDeleteConfirm(row)}
                  icon={
                    <div className="h-[24px] rounded-full p-[3px] hover:bg-red-100">
                      <TrashIcon className="h-full fill-red" />
                    </div>
                  }
                  iconOnly
                  type="button"
                  confirm={rowDeleteConfirm ? rowDeleteConfirm(row) : null}
                />
              )}
              header="Delete"
            />
          )}

          {areRowsEditable && <Column header="Edit" rowEditor frozen alignFrozen="right" />}
        </Table>
      </div>
    )
  },
)

export default DataTable

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  containerClassName: PropTypes.string,
  columns: PropTypes.array.isRequired,
  emptyMessage: PropTypes.string,
  loading: PropTypes.bool,
  loadingDelete: PropTypes.bool,
  pagination: PropTypes.shape({
    first: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalRecords: PropTypes.number.isRequired,
  }).isRequired,
  sorting: PropTypes.shape({
    sortField: PropTypes.string.isRequired,
    sortOrder: PropTypes.number.isRequired,
  }).isRequired,
  onRowEditComplete: PropTypes.func,
  onRowDeleteConfirm: PropTypes.func,
  areRowsDeleteable: PropTypes.bool,
  areRowsEditable: PropTypes.bool,
  onRowClick: PropTypes.func,
  hoverEffect: PropTypes.bool,
  rowDeleteConfirm: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  filters: PropTypes.object,
  onFilter: PropTypes.func,
  rowExpansionTemplate: PropTypes.func,
  scrollHeight: PropTypes.string,
}
