import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'

// Components
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Select } from '../../components/Select'

// Store
import { TaskStoreContext } from '../../stores/TaskStore'

// Utils & Services
import { createClientQcCheckExport } from '../../services/clients.service'
import { createClientDataExport } from '../../services/portfolio.service'
import { toast } from '../../utils/helpers'

const ExportDataModal = ({ client, closeModal, periods }) => {
  // Context
  const { addTask } = useContext(TaskStoreContext)

  // State
  const [loading, setLoading] = useState(false)

  const {
    formState: { errors },
    handleSubmit,
    control,
    clearErrors,
    getValues,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      startPeriod: null,
      endPeriod: null,
      numOfMonthsRollForward: null,
      isQcCheckIncluded: false,
    },
  })

  const handleErrors = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  useEffect(() => {
    // Clear the end period value if the number of months to roll forward is set
    const months = watch('numOfMonthsRollForward')
    if (months && months > 0 && getValues('endPeriod') !== null) {
      setValue('endPeriod', null)
    }
  }, [watch('numOfMonthsRollForward')])

  /**
   * Handles submitting the create data export request.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const response = await createClientDataExport(
      client.id,
      {
        startPeriod: data.startPeriod?.value || null,
        endPeriod: data.endPeriod?.value || null,
        numOfMonthsRollForward: data.numOfMonthsRollForward || null,
        isQcCheckIncluded: data.isQcCheckIncluded || false,
      },
      handleErrors,
      setLoading,
      handleSuccess,
    )

    if (response) {
      const startPeriod = data.startPeriod?.value || periods[0].value
      const endPeriod = data.endPeriod?.value || periods[periods.length - 1].value
      const rollForward = data.numOfMonthsRollForward
      let fileNameSuffix = `${startPeriod}_${endPeriod}`
      if (rollForward) {
        fileNameSuffix = `${fileNameSuffix}_RollForward_${rollForward}_Months`
      }

      addTask({
        type: 'export',
        id: response.id,
        data: response,
        downloaded: false,
        fileName: `${client.name}_${fileNameSuffix}.xlsx`,
      })
      closeModal()
    }
  }

  const actions = [
    {
      type: 'submit',
      label: 'Export Data',
      onClick: handleSubmit(onSubmit),
    },
    { type: 'cancel', label: 'Cancel', onClick: closeModal },
  ]

  if (client.latestQcCheck) {
    actions.push({
      type: 'extra',
      label: 'Export QC Check',
      onClick: async () => {
        const response = await createClientQcCheckExport(
          client.id,
          client.latestQcCheck.id,
          handleErrors,
          setLoading,
          handleSuccess,
        )

        if (response) {
          addTask({
            type: 'qc-export',
            id: response.id,
            data: response,
            downloaded: false,
            fileName: `${client.name}_QC_Check.xlsx`,
          })
          closeModal()
        }
      },
    })
  }

  return (
    <Modal
      icon={<CloudArrowDownIcon className="h-6 stroke-white" />}
      open
      title="Generate Data Export"
      description="Export data for the selected period range and optionally roll-forward data.
      Leave the periods blank to export all available data."
      loading={loading}
      onClose={closeModal}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-8 flex size-full flex-col gap-4 pt-2"
        >
          <div className="flex size-full flex-row gap-4">
            <Controller
              name="startPeriod"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  label="Start Period"
                  style={{ width: '100%' }}
                  error={errors.startPeriod && 'This field is required'}
                  onChange={(option) => {
                    onChange(option)
                    clearErrors('startPeriod')
                  }}
                  options={periods}
                />
              )}
            />

            <Controller
              name="endPeriod"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  label="End Period"
                  style={{ width: '100%' }}
                  error={errors.endPeriod && 'This field is required'}
                  onChange={(option) => {
                    onChange(option)
                    clearErrors('endPeriod')
                  }}
                  options={periods}
                />
              )}
            />
          </div>

          <TextInput
            fullWidth
            label="Number of Months to Roll Forward"
            subLabel="Setting a Roll Forward amount will clear out the selection for the End Period."
            name="numOfMonthsRollForward"
            type="number"
            error={errors.numOfMonthsRollForward && 'This field is required'}
            min={1}
            {...register('numOfMonthsRollForward')}
          />

          {client?.latestQcCheck && (
            <div className="flex flex-row place-items-center gap-2">
              <input
                id="isQcCheckIncluded"
                name="isQcCheckIncluded"
                {...register('isQcCheckIncluded', { required: false, value: true })}
                type="checkbox"
                className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
              />
              <label htmlFor="isQcCheckIncluded" className="text-sm font-medium text-gray-900">
                Include QC Check
              </label>
            </div>
          )}
        </form>
      }
      actions={actions}
    />
  )
}

ExportDataModal.propTypes = {
  client: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  periods: PropTypes.array.isRequired,
}

export default ExportDataModal
