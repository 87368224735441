import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { renderMultiSelectFilter } from '../../components/CustomEditor'
import { DataTable, DEFAULT_FILTER_OPTIONS } from '../../components/DataTable'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'
import UploadBenchmarkImportModal from './UploadBenchmarkImportModal'

// Hooks
import { usePagination, useSorting } from '../../hooks/DataTableManagement'

// Utils & Service
import {
  getGlobalBenchmarkCreators,
  getGlobalBenchmarkImports,
} from '../../services/global.service'
import { configureFilterQuery, hasAnyFilterSelected, toast } from '../../utils/helpers'

const DEFAULT_FILTERS = {
  created_by: { value: [] },
}

const GlobalBenchmarks = () => {
  const [searchParams] = useSearchParams()

  // Pagination
  const { pagination, setTotalRecords } = usePagination(50)
  const { perPage, currentPage } = pagination

  // Sorting
  const { sorting } = useSorting('period')
  const { sortedColumn } = sorting

  // State
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [columns, setColumns] = useState([])
  const [benchmarkImports, setBenchmarkImports] = useState([])
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const handleErrors = (m) => toast(m, 'error')

  const BASE_GLOBAL_BENCHMARK_IMPORTS_URL = '/global-benchmark-imports/?expand=created_by'

  /**
   * Renders a download button for the row.
   * @param {object} row
   */
  const renderDownloadButton = (row) => (
    <Button
      background="bg-white"
      disabled={!row.file}
      loading={loadingDownload === row.id}
      showConfirmDialog
      onClick={async () => {
        setLoadingDownload(row.id)

        const response = await fetch(row.file)
        const blobUrl = await response.blob()
        const objectUrl = window.URL.createObjectURL(blobUrl)
        const downloadLink = document.createElement('a')
        downloadLink.href = objectUrl
        downloadLink.download = row.originalFilename
        downloadLink.target = '_blank'
        downloadLink.click()
        downloadLink.remove()

        setLoadingDownload(false)
      }}
      label="Download"
      type="button"
    />
  )

  useEffect(() => {
    const getUpdatedFilterData = async () => {
      const creators = await getGlobalBenchmarkCreators(handleErrors, setLoading)

      const updatedCreators = _.map(creators, (creator) => ({
        label: `${creator.firstName} ${creator.lastName}`,
        value: creator.id,
        id: creator.id,
      }))

      const DEFAULT_COLUMNS = [
        {
          field: 'createdAt',
          header: 'Created At',
          body: (row) => dayjs(row.createdAt).format('MM/DD/YYYY h:mm A'),
          sortField: 'created_at',
          sortable: true,
          style: { minWidth: '100px' },
        },
        {
          field: 'createdBy',
          header: 'Created By',
          body: (row) => `${row.createdBy.firstName} ${row.createdBy.lastName}`,
          sortField: 'created_by__first_name',
          sortable: true,
          style: { minWidth: '100px' },
          filterField: 'created_by',
          ...DEFAULT_FILTER_OPTIONS,
          filterElement: (options) =>
            renderMultiSelectFilter(
              updatedCreators,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  created_by: {
                    value: selected,
                  },
                }))
              },
              'Filter by Creator',
              options,
            ),
        },
        {
          field: 'originalFilename',
          header: 'File Name',
          sortField: 'original_filename',
          sortable: true,
          style: { minWidth: '250px' },
        },
        {
          field: 'view',
          header: '',
          center: 'true',
          body: (row) => renderDownloadButton(row),
          sortable: false,
          style: { width: '80px' },
        },
      ]

      setColumns(DEFAULT_COLUMNS)
    }

    getUpdatedFilterData()
  }, [])

  useEffect(() => {
    // If there are `searchParams`, apply the filters
    if (searchParams) {
      const params = Object.fromEntries(searchParams)
      const updatedFilters = { ...filters }

      if (params.created_by) {
        updatedFilters.created_by = { value: [params.created_by] }
      }

      setFilters(updatedFilters)
    }
  }, [searchParams])

  const getUpdatedBenchmarkImports = async (url) => {
    const response = await getGlobalBenchmarkImports(url, setError, setLoading)

    if (response) {
      setTotalRecords(response.count)
      setBenchmarkImports(response.results)
    }
  }

  /**
   * When the filter, sort, current page, or row count changes, get the updated list of data.
   */
  useEffect(() => {
    const queryFilters = configureFilterQuery({
      created_by: filters.created_by || DEFAULT_FILTERS.created_by,
    })

    getUpdatedBenchmarkImports(
      `${BASE_GLOBAL_BENCHMARK_IMPORTS_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}&${queryFilters}`,
    )
  }, [perPage, sortedColumn, currentPage, filters])

  return (
    <PageContainer withPadding>
      <StateContainer error={error} loading={loading}>
        <div className="h-[calc(100vh-200px)] w-full">
          <div className="mb-5 flex w-full flex-col justify-between sm:flex-row sm:items-center">
            <h3 className="text-xl font-semibold leading-6 text-gray-900">
              Global Benchmark Imports
            </h3>

            <div className="mb-4 flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
              {hasAnyFilterSelected(filters) && (
                <Button
                  label="Clear Filters"
                  background="bg-gray"
                  onClick={() => {
                    setFilters(DEFAULT_FILTERS)
                  }}
                />
              )}

              <Button
                label="Upload Benchmark File"
                background="bg-blue"
                onClick={() => setShowUploadModal(true)}
              />
            </div>
          </div>

          <DataTable
            columns={columns}
            data={benchmarkImports}
            perPage={perPage}
            loading={loading}
            pagination={pagination}
            sorting={sorting}
            filters={filters}
            onFilter={(e) => setFilters(e.filters)}
            hoverEffect={false}
          />
        </div>
      </StateContainer>

      {showUploadModal && (
        <UploadBenchmarkImportModal
          closeModal={() => {
            setShowUploadModal(false)
            getUpdatedBenchmarkImports(
              `${BASE_GLOBAL_BENCHMARK_IMPORTS_URL}&order_by=${sortedColumn}&limit=${perPage}&page=1`,
            )
          }}
        />
      )}
    </PageContainer>
  )
}

export default GlobalBenchmarks
