import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { PlusIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import dayjs from 'dayjs'

// Components
import { Button } from '../../components/Button'
import { CalendarInput } from '../../components/CalendarInput'
import {
  DateEditor,
  TextAreaEditor,
  TextEditor,
  renderSelectEditor,
  renderMultiSelectFilter,
  renderStatusTag,
} from '../../components/CustomEditor'
import { DataTable, DEFAULT_FILTER_OPTIONS } from '../../components/DataTable'
import { Modal } from '../../components/Modal'
import { PageContainer } from '../../components/PageContainer'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { TextArea } from '../../components/TextArea'
import { TextInput } from '../../components/TextInput'

// Services
import { getClient } from '../../services/clients.service'
import { getClientBenchmarks } from '../../services/benchmarks.service'
import {
  createClientDisclosure,
  deleteClientDisclosure,
  getClientDisclosures,
  updateClientDisclosure,
} from '../../services/disclosures.service'

// Stores
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

// Utils & Hooks
import { toast } from '../../utils/helpers'
import { usePagination, useSorting } from '../../hooks/DataTableManagement'
import {
  DISCLOSURE_DESCRIPTION_OPTIONS,
  ORIENTATION,
  REPORT_OPTIONS,
  YES_OR_NO,
} from '../../utils/constants'

const DEFAULT_FILTERS = {
  benchmark: { value: [] },
}

/**
 *
 * Disclosures
 *
 */
const Disclosures = observer(() => {
  // Context
  const { clientId } = useParams()
  const { client, setClient } = useContext(ClientDashboardStoreContext)

  // Pagination
  const { pagination, setTotalRecords } = usePagination(10)
  const { perPage, currentPage } = pagination

  // Sorting
  const { sorting } = useSorting('name')
  const { sortedColumn } = sorting

  // State
  const [loadingClient, setLoadingClient] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [loadingDisclosures, setLoadingDisclosures] = useState(false)
  const [columns, setColumns] = useState([])
  const [benchmarks, setBenchmarks] = useState([])
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [error, setError] = useState(null)
  const [disclosures, setDisclosures] = useState([])
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [showAddDisclosureModal, setShowAddDisclosureModal] = useState(false)

  const BASE_URL = `/clients/${clientId}/disclosures/?expand=benchmark`

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated list of disclosures; updates pagination.
   * @param {string} url
   */
  const getUpdatedDisclosures = async (url) => {
    const response = await getClientDisclosures(url, setError, setLoadingDisclosures)

    if (response) {
      setTotalRecords(response.count)
      setDisclosures(response.results)
    }
  }

  useEffect(() => {
    const getClientData = async () => {
      const updatedClient = await getClient(clientId, setError, setLoadingClient)
      if (updatedClient) {
        setClient(updatedClient)
      }

      const clientBenchmarks = await getClientBenchmarks(
        `/clients/${clientId}/benchmarks/?limit=99999`,
        handleErrors,
        setLoadingData,
      )

      const updatedBenchmarks = _.map(clientBenchmarks.results, (d) => ({
        label: d.name,
        value: d.id,
        id: d.id,
      }))

      // Update editors and filters to include options
      const updatedColumns = [
        {
          field: 'firmName',
          header: 'Firm Name',
          editor: TextEditor,
          sortable: true,
          style: { minWidth: '250px' },
        },
        {
          field: 'firmDefinition',
          header: 'Firm Definition',
          editor: TextAreaEditor,
          sortable: true,
          style: { minWidth: '400px' },
        },
        {
          field: 'listOfDescriptions',
          header: 'List of Descriptions',
          sortable: true,
          body: (rowData) => rowData.listOfDescriptions,
          style: { minWidth: '250px' },
          editor: (options) =>
            renderSelectEditor(
              DISCLOSURE_DESCRIPTION_OPTIONS,
              options.rowData.listOfDescriptions,
              'Select an Option',
              options,
            ),
        },
        {
          field: 'verifiedFrom',
          header: 'Verified From',
          body: (row) => row?.verifiedFrom && dayjs(row.verifiedFrom).format('MM/DD/YYYY'),
          editor: DateEditor,
          sortable: true,
          style: { minWidth: '150px' },
        },
        {
          field: 'verifiedTo',
          header: 'Verified To',
          body: (row) => row.verifiedTo && dayjs(row.verifiedTo).format('MM/DD/YYYY'),
          editor: DateEditor,
          sortable: true,
          style: { minWidth: '150px' },
        },
        {
          field: 'gipsReportThroughDate',
          header: 'GIPS Report Through Date',
          body: (row) =>
            row.gipsReportThroughDate && dayjs(row.gipsReportThroughDate).format('MM/DD/YYYY'),
          editor: DateEditor,
          sortable: true,
          style: { minWidth: '150px' },
        },
        {
          field: 'useHouseholdTool',
          header: 'Use Household Tool',
          sortable: true,
          body: (rowData) => renderStatusTag(rowData.useHouseholdTool === 'Yes'),
          style: { minWidth: '190px' },
          editor: (options) =>
            renderSelectEditor(
              YES_OR_NO,
              options.rowData.useHouseholdTool,
              'Select an Option',
              options,
            ),
        },
        {
          field: 'reportFormat',
          header: 'Report Format',
          sortable: true,
          body: (rowData) => rowData.reportFormat,
          style: { minWidth: '170px' },
          editor: (options) =>
            renderSelectEditor(
              REPORT_OPTIONS,
              options.rowData.reportFormat,
              'Select an Option',
              options,
            ),
        },
        {
          field: 'color',
          header: 'Color',
          editor: TextEditor,
          sortable: true,
          style: { minWidth: '150px' },
        },
        {
          field: 'logo',
          header: 'Logo',
          editor: TextEditor,
          sortable: true,
          style: { minWidth: '250px' },
        },
        {
          field: 'orientation',
          header: 'Orientation',
          sortable: true,
          body: (rowData) => rowData.orientation,
          style: { minWidth: '170px' },
          editor: (options) =>
            renderSelectEditor(
              ORIENTATION,
              options.rowData.orientation,
              'Select an Option',
              options,
            ),
        },
        {
          field: 'footnote',
          header: 'Footnote',
          editor: TextAreaEditor,
          sortable: true,
          style: { minWidth: '300px' },
        },
        {
          field: 'benchmark.id',
          header: 'Benchmark',
          body: (rowData) => rowData.benchmark.name,
          sortField: 'benchmark__name',
          sortable: true,
          style: { minWidth: '250px' },
          filterField: 'benchmark',
          ...DEFAULT_FILTER_OPTIONS,
          editor: (options) =>
            renderSelectEditor(
              updatedBenchmarks,
              options.rowData.benchmark.id,
              'Select a Benchmark',
              options,
            ),
          filterElement: (options) =>
            renderMultiSelectFilter(
              updatedBenchmarks,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  benchmark: {
                    value: selected,
                  },
                }))
              },
              'Filter by Benchmark',
              options,
            ),
        },
        {
          field: 'reportBenchmarkName',
          header: 'Benchmark Report Name',
          editor: TextEditor,
          sortable: true,
          style: { minWidth: '300px' },
        },
        {
          field: 'descriptions',
          header: 'Descriptions',
          editor: TextAreaEditor,
          sortable: true,
          style: { minWidth: '650px' },
        },
      ]

      setBenchmarks(updatedBenchmarks)
      setColumns(updatedColumns)
    }

    getClientData()
  }, [clientId])

  /**
   * When the filter, sort, current page, or row count changes, get the updated list of disclosures.
   */
  useEffect(() => {
    if (clientId) {
      getUpdatedDisclosures(
        `${BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
      )
    }
  }, [perPage, clientId, sortedColumn, currentPage])

  const {
    control,
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
    },
  })

  /**
   * Handles form submission by creating a new disclosure for the client.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const payload = { ...data }
    payload.benchmark = payload.benchmark.id

    createClientDisclosure(client.id, payload, handleErrors, setLoadingData, (m) => {
      handleSuccess(m)
      reset()
      getUpdatedDisclosures(
        `${BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
      )
      setShowAddDisclosureModal(false)
    })
  }

  /**
   * Handles row edit completion by updating the local `disclosures` with new data and
   * submitting it to the backend.
   * @param {object} event
   */
  const onRowEditComplete = async ({ newData, index }) => {
    const oldDisclosures = [...disclosures]
    const updatedData = [...disclosures]

    if (newData.verifiedFrom instanceof Date) {
      const [date] = newData.verifiedFrom.toISOString().split('T')
      // eslint-disable-next-line no-param-reassign
      newData.verifiedFrom = date
    }
    if (newData.verifiedTo instanceof Date) {
      const [date] = newData.verifiedTo.toISOString().split('T')
      // eslint-disable-next-line no-param-reassign
      newData.verifiedTo = date
    }
    if (newData.gipsReportThroughDate instanceof Date) {
      const [date] = newData.gipsReportThroughDate.toISOString().split('T')
      // eslint-disable-next-line no-param-reassign
      newData.gipsReportThroughDate = date
    }

    // Update the benchmark (if applicable) to update the stored values
    updatedData[index] = newData
    updatedData[index].benchmark = benchmarks.find((b) => b.id === newData.benchmark.id)

    // Optimistically update the data to prevent showing internal values
    setDisclosures(updatedData)

    await updateClientDisclosure(
      clientId,
      {
        ...newData,
        benchmark: newData.benchmark.id,
      },
      (m) => {
        // Reset back to previous value
        setDisclosures(oldDisclosures)
        handleErrors(m)
      },
      () => {},
      (m) => handleSuccess(m),
    )
  }

  return (
    <PageContainer>
      <StateContainer error={error} loading={loadingClient}>
        <div className="flex size-full flex-col overflow-y-auto bg-background">
          <div className="space-y-6">
            <div className="flex w-full flex-col justify-between sm:flex-row sm:items-center">
              <h2 className="text-xl font-semibold leading-6 text-gray-900">Disclosures</h2>

              <Button label="Add Disclosure" onClick={() => setShowAddDisclosureModal(true)} />
            </div>

            <DataTable
              data={disclosures}
              columns={columns}
              loadingDelete={loadingDelete}
              hoverEffect={false}
              loading={loadingData || loadingDisclosures}
              pagination={pagination}
              sorting={sorting}
              onRowEditComplete={onRowEditComplete}
              onRowDeleteConfirm={(row) => {
                deleteClientDisclosure(clientId, row.id, handleErrors, setLoadingDelete, () => {
                  handleSuccess('Disclosure deleted.')
                  getUpdatedDisclosures(
                    `${BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                  )
                })
              }}
              areRowsDeleteable
              areRowsEditable
              rowDeleteConfirm={(row) => ({
                title: `Delete Disclosure`,
                type: 'warning',
                message: (
                  <div className="flex flex-col">
                    <span className="">Are you sure you want to delete the disclosure:</span>
                    <span className="font-semibold">{row.benchmark.name}</span>
                    <span className="font-semibold">{row.firmName}</span>
                  </div>
                ),
              })}
              filters={filters}
              onFilter={(e) => setFilters(e.filters)}
            />
          </div>
        </div>
      </StateContainer>

      <Modal
        icon={<PlusIcon className="h-6 fill-white" />}
        open={showAddDisclosureModal}
        title="Add Disclosure"
        loading={loadingData}
        onClose={() => {
          setShowAddDisclosureModal(false)
          reset()
        }}
        content={
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-8 flex size-full flex-col gap-4 pt-2"
          >
            <TextInput fullWidth label="Firm Name" name="firmName" {...register('firmName')} />

            <TextArea
              fullWidth
              label="Firm Definition"
              name="firmDefinition"
              {...register('firmDefinition')}
            />

            <Controller
              name="listOfDescriptions"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  label="List of Descriptions"
                  style={{ width: '100%' }}
                  onChange={(option) => {
                    onChange(option)
                    clearErrors('listOfDescriptions')
                  }}
                  options={DISCLOSURE_DESCRIPTION_OPTIONS}
                />
              )}
            />

            <div className="flex flex-row gap-2">
              <Controller
                name="verifiedFrom"
                control={control}
                render={({ field: { value } }) => (
                  <CalendarInput
                    label="Verified From"
                    id="verifiedFrom"
                    fullWidth
                    value={value}
                    onChange={(d) => setValue('verifiedFrom', d)}
                  />
                )}
              />

              <Controller
                name="verifiedTo"
                control={control}
                render={({ field: { value } }) => (
                  <CalendarInput
                    label="Verified To"
                    id="verifiedTo"
                    fullWidth
                    value={value}
                    onChange={(d) => setValue('verifiedTo', d)}
                  />
                )}
              />
            </div>

            <Controller
              name="gipsReportThroughDate"
              control={control}
              render={({ field: { value } }) => (
                <CalendarInput
                  label="GIPS Report Through Date"
                  id="gipsReportThroughDate"
                  value={value}
                  onChange={(d) => setValue('gipsReportThroughDate', d)}
                />
              )}
            />

            <div className="flex flex-row gap-2">
              <Controller
                name="useHouseholdTool"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value}
                    label="Use Household Tool"
                    style={{ width: '100%' }}
                    onChange={(option) => {
                      onChange(option)
                      clearErrors('useHouseholdTool')
                    }}
                    options={YES_OR_NO}
                  />
                )}
              />

              <Controller
                name="reportFormat"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value}
                    label="Report Format"
                    style={{ width: '100%' }}
                    onChange={(option) => {
                      onChange(option)
                      clearErrors('reportFormat')
                    }}
                    options={REPORT_OPTIONS}
                  />
                )}
              />
            </div>

            <div className="flex flex-row gap-2">
              <TextInput fullWidth label="Color" name="color" {...register('color')} />

              <TextInput fullWidth label="Logo" name="logo" {...register('logo')} />
            </div>

            <Controller
              name="orientation"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  label="Orientation"
                  style={{ width: '100%' }}
                  onChange={(option) => {
                    onChange(option)
                    clearErrors('orientation')
                  }}
                  options={ORIENTATION}
                />
              )}
            />

            <TextArea fullWidth label="Footnote" name="footnote" {...register('footnote')} />

            <Controller
              name="benchmark"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  label="Benchmark"
                  style={{ width: '100%' }}
                  onChange={(option) => {
                    onChange(option)
                    clearErrors('benchmark')
                  }}
                  options={benchmarks}
                />
              )}
            />

            <TextInput
              fullWidth
              label="Report Benchmark Name"
              name="reportBenchmarkName"
              {...register('reportBenchmarkName')}
            />

            <TextArea
              fullWidth
              label="Descriptions"
              name="descriptions"
              {...register('descriptions')}
            />
          </form>
        }
        actions={[
          {
            type: 'submit',
            label: 'Add',
            onClick: handleSubmit(onSubmit),
          },
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              setShowAddDisclosureModal(false)
              reset()
            },
          },
        ]}
      />
    </PageContainer>
  )
})

export default Disclosures
