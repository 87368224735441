import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { ClientHeader } from '../../components/ClientHeader'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'

// Services
import { getClient } from '../../services/clients.service'

// Stores
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

const TABS = [
  { label: 'Composites', path: '/composites' },
  { label: 'Benchmarks', path: '/benchmarks' },
  { label: 'Disclosures', path: '/disclosures' },
  { label: 'Rules', path: '/rules' },
]

/**
 *
 * ClientSettings
 *
 */
const ClientSettings = observer(() => {
  // Context
  const location = useLocation()
  const navigate = useNavigate()
  const { clientId } = useParams()
  const { client, setClient } = useContext(ClientDashboardStoreContext)

  // State
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [active, setActive] = useState(TABS[0])

  /**
   * Gets the updated client.
   */
  const getUpdatedData = async () => {
    const updatedClient = await getClient(clientId, setError, setLoading)

    if (updatedClient) {
      setClient(updatedClient)
    }
  }

  useEffect(() => {
    // If path does not match any of the tabs, redirect to the first tab
    if (!TABS.some((tab) => location.pathname.includes(tab.path))) {
      navigate(`/clients/${clientId}/settings${TABS[0].path}`)
    }
    // Otherwise, set the active tab based on the path
    else {
      setActive(TABS.find((tab) => location.pathname.includes(tab.path)))
    }
  }, [location.pathname])

  useEffect(() => {
    if (clientId) {
      getUpdatedData()
    }
  }, [clientId])

  return (
    <PageContainer>
      <StateContainer error={error} loading={loading}>
        <div className="size-full">
          <ClientHeader client={client} />

          <div className="mt-4 place-self-center sm:w-2/3">
            <nav
              aria-label="Tabs"
              className="isolate flex divide-x divide-gray-100 rounded-lg border border-gray-100 shadow-sm"
            >
              {TABS.map((tab, i) => (
                <Link
                  key={tab.label}
                  to={`/clients/${clientId}/settings${tab.path}`}
                  aria-current={active === tab ? 'page' : undefined}
                  className={mergeClassNames(
                    active === tab ? 'text-blue' : 'text-gray-500 hover:text-gray-700',
                    i === 0 ? 'rounded-l-lg' : '',
                    i === TABS.length - 1 ? 'rounded-r-lg' : '',
                    'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
                  )}
                >
                  <span>{tab.label}</span>
                  <span
                    aria-hidden="true"
                    className={mergeClassNames(
                      active === tab ? 'bg-blue' : 'bg-transparent',
                      'absolute inset-x-0 bottom-0 h-0.5',
                    )}
                  />
                </Link>
              ))}
            </nav>
          </div>

          <div className="flex h-[calc(100vh-220px)] w-full flex-col overflow-y-auto bg-background px-4 pb-12 pt-2 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </div>
      </StateContainer>
    </PageContainer>
  )
})

export default ClientSettings
