import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { ClientHeader } from '../../components/ClientHeader'
import { renderMultiSelectFilter } from '../../components/CustomEditor'
import { DataTable, DEFAULT_FILTER_OPTIONS } from '../../components/DataTable'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'

// Services
import { getActivityOptions, getActivityLog } from '../../services/activityLog.service'
import { getClient } from '../../services/clients.service'

// Stores
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

// Hooks
import { usePagination, useSorting } from '../../hooks/DataTableManagement'

// Utils
import { configureFilterQuery, hasAnyFilterSelected, toast } from '../../utils/helpers'

const VERB_OPTIONS = [
  { id: 'approved', label: 'Approved' },
  { id: 'canceled', label: 'Canceled' },
  { id: 'created', label: 'Created' },
  { id: 'deleted', label: 'Deleted' },
  { id: 'failed', label: 'Failed' },
  { id: 'generated', label: 'Generated' },
  { id: 'modified', label: 'Modified' },
  { id: 'rejected', label: 'Rejected' },
  { id: 'started', label: 'Started' },
  { id: 'triggered', label: 'Triggered' },
]

const DEFAULT_FILTERS = {
  actor_object_id: { value: [] },
  verb: { value: [] },
  action_object_content_type: { value: [] },
}

/**
 *
 * ClientActivity
 *
 */
const ClientActivity = observer(() => {
  // Context
  const { clientId } = useParams()
  const { client, setClient } = useContext(ClientDashboardStoreContext)

  const { pagination, setTotalRecords } = usePagination(50)
  const { perPage, currentPage } = pagination

  // State
  const [loadingClient, setLoadingClient] = useState(true)
  const [error, setError] = useState(null)
  const [activityLog, setActivityLog] = useState(null)
  const [loadingActivityLog, setLoadingActivityLog] = useState(null)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [actorOptions, setActorOptions] = useState([])
  const [contentTypes, setContentTypes] = useState([])
  const [contentTypeLabels, setContentTypeLabels] = useState([])

  // Sorting
  const { sorting } = useSorting()
  const { sortedColumn } = sorting

  const dataTable = useRef()

  const handleError = (m) => toast(m, 'error')

  const ACTIVITY_BASE_URL = `/clients/${clientId}/activity-log/`

  /**
   * Handles getting the updated activity log; updates data and pagination.
   * @param {string} url
   */
  const getUpdatedActivityLog = async (url) => {
    const response = await getActivityLog(url, handleError, setLoadingActivityLog)

    if (response) {
      setTotalRecords(response.count)
      setActivityLog(response.results)
    }
  }

  /**
   * Gets the updated client.
   */
  const getUpdatedData = async () => {
    const updatedClient = await getClient(clientId, setError, setLoadingClient)
    const activityOptions = await getActivityOptions(clientId)

    if (updatedClient) {
      setClient(updatedClient)
    }

    if (activityOptions.actorOptions) {
      setActorOptions(
        activityOptions.actorOptions.map((actor) => ({
          label: `${actor.firstName} ${actor.lastName}`,
          id: actor.id,
        })),
      )
    }

    if (activityOptions.contentTypeOptions) {
      setContentTypes(activityOptions.contentTypeOptions)
      setContentTypeLabels(
        Object.entries(activityOptions.contentTypeOptions).map(([id, label]) => ({
          id: Number(id),
          label,
        })),
      )
    }
  }

  useEffect(() => {
    if (clientId) {
      getUpdatedData()
    }
  }, [clientId])

  /**
   * When the filter, sort, current page, or row count changes, get the updated list of portfolio data.
   */
  useEffect(() => {
    if (clientId) {
      const queryFilters = configureFilterQuery({
        actor_object_id: filters.actor_object_id || DEFAULT_FILTERS.actor_object_id,
        verb: filters.verb || DEFAULT_FILTERS.verb,
        action_object_content_type:
          filters.action_object_content_type || DEFAULT_FILTERS.action_object_content_type,
      })

      getUpdatedActivityLog(
        `${ACTIVITY_BASE_URL}?limit=${perPage}&order_by=${sortedColumn}&page=${currentPage}&${queryFilters}`,
      )
    }
  }, [perPage, clientId, currentPage, sortedColumn, filters])

  const renderItemChanged = (row) => {
    const type = contentTypes[row.actionObjectContentType]
    let to = null
    let label = 'Unknown'

    switch (type) {
      case 'Client': {
        if (row.verb === 'approved') {
          label = 'Approved SCF and Mins Check'
        } else if (row.description === 'requires rules check') {
          const modificationReason = row.data.actionFrom.replace('modified', 'modification')
          label = `A new SCF and Mins Check is required due to ${modificationReason}`
        }
        break
      }
      case 'Global Benchmark Data Import':
        to = `/global-benchmarks/?created_by=${row.actor.id}`
        label = type
        break
      case 'Client Account': {
        to = `/clients/${row.target.id}/settings/accounts`
        label = type

        if (row.data) {
          label = `${type}: ${row.data.name} (${row.data.number})`
        }

        break
      }
      case 'Client Rules': {
        to = `/clients/${row.target.id}/settings/rules`
        label = type

        if (row.data) {
          label = `${type}: ${row.data.composite}`
        }

        break
      }
      case 'Client Benchmark': {
        to = `/clients/${row.target.id}/settings/benchmarks`
        label = type

        if (row.data) {
          label = `${type}: ${row.data.name} (${row.data.component})`
        }

        break
      }
      case 'Client Composite': {
        to = `/clients/${row.target.id}/settings/composites`
        label = type

        if (row.data) {
          label = `${type}: ${row.data.name}`
        }
        break
      }
      case 'Client Disclosure': {
        to = `/clients/${row.target.id}/settings/disclosures`
        label = type

        if (row.data) {
          label = `${type}: ${row.data.firmName} (${row.data.benchmarkName})`
        }

        break
      }
      case 'Client Portfolio': {
        to = `/clients/${row.target.id}/settings/portfolios`
        label = type

        if (row.data) {
          label = `${type}: ${row.data.name} (${row.data.number})`
        }
        break
      }
      case 'Client Data Import': {
        to = `/clients/${row.target.id}/imports`
        label = type

        if (row.data) {
          label = `${type} from ${dayjs(row.data.createdAt).format('MM/DD/YYYY h:mm A')}`
        }
        break
      }
      case 'Client Pending Portfolio Data': {
        to = `/clients/${row.target.id}/imports`
        label = type

        if (row.data) {
          const { composite, period } = row.data
          label = `${type} for ${composite}: ${dayjs(period).format('MM/DD/YYYY')}`
        }

        break
      }
      case 'Client Portfolio Data': {
        let query = ''
        label = type

        if (row.data) {
          const { accountId, composite, compositeId, period } = row.data
          query = `?composite=${compositeId}&period=${period}&account=${accountId}`
          label = `${type} for ${composite}: ${dayjs(period).format('MM/DD/YYYY')}`
        }

        to = `/clients/${row.target.id}/portfolio-data${query}`
        break
      }
      case 'Client Benchmark Data': {
        to = `/clients/${row.target.id}/benchmark-data`
        label = type

        if (row.data) {
          const { composite, period } = row.data
          label = `${type} for ${composite}: ${dayjs(period).format('MM/DD/YYYY')}`
        }

        break
      }
      case 'Client AUM Data': {
        to = `/clients/${row.target.id}/aum-data`
        label = type

        if (row.data) {
          label = `${type} for ${dayjs(row.data.year).format('MM/DD/YYYY')}`
        }

        break
      }
      case 'Report': {
        label = type
        if (row.actionObject.id) {
          to = `/clients/${row.target.id}/dashboard/reports/${row.actionObject.id}`
        }

        if (row.data) {
          const { createdAt } = row.data
          label = `${type} from ${dayjs(createdAt).format('MM/DD/YYYY h:mm A')}`
        }

        break
      }
      default:
        break
    }

    if (to) {
      return (
        <Link className="font-medium text-blue-700 hover:text-blue-dark hover:underline" to={to}>
          {label}
        </Link>
      )
    }
    return label
  }

  const COLUMNS = [
    {
      field: `actor_object_id`,
      header: 'User',
      body: (row) => (
        <div className="flex flex-col">
          <span className="mr-2">{`${row.actor.firstName} ${row.actor.lastName}`}</span>
          <span>({row.actor.email})</span>
        </div>
      ),
      sortable: true,
      ...DEFAULT_FILTER_OPTIONS,
      filterElement: (options) =>
        renderMultiSelectFilter(
          actorOptions,
          (selected) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              actor_object_id: {
                value: selected,
              },
            }))
          },
          'Filter by User',
          options,
        ),
    },
    {
      field: 'timestamp',
      header: 'Date',
      body: (row) => dayjs(row.timestamp).format('MM/DD/YYYY h:mm A'),
      sortable: true,
      style: { minWidth: '175px' },
    },
    {
      field: 'verb',
      header: 'Action',
      body: (row) => <span className="capitalize">{row.verb}</span>,
      sortable: true,
      ...DEFAULT_FILTER_OPTIONS,
      filterElement: (options) =>
        renderMultiSelectFilter(
          VERB_OPTIONS,
          (selected) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              verb: {
                value: selected,
              },
            }))
          },
          'Filter by Action',
          options,
        ),
    },
    {
      field: 'action_object_content_type',
      header: 'Item Changed',
      body: (row) => renderItemChanged(row),
      filterField: 'action_object_content_type',
      ...DEFAULT_FILTER_OPTIONS,
      filterElement: (options) =>
        renderMultiSelectFilter(
          contentTypeLabels,
          (selected) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              action_object_content_type: {
                value: selected,
              },
            }))
          },
          'Filter by Type of Item Changed',
          options,
        ),
      style: { maxWidth: '450px' },
    },
    {
      field: 'actionObject',
      header: '',
      body: (row) => {
        if (
          contentTypes[row.actionObjectContentType] === 'Client Data Import' &&
          row.actionObject?.tabsProcessed
        ) {
          return (
            <span>
              <span className="mr-1 font-semibold">Processed Tabs:</span>
              {row.actionObject.tabsProcessed.join(', ')}
            </span>
          )
        }

        return ''
      },
      style: { minWidth: '250px' },
    },
  ]

  return (
    <PageContainer>
      <StateContainer error={error} loading={loadingClient}>
        <div className="size-full">
          <ClientHeader client={client} />

          <div className="flex h-[calc(100vh-150px)] w-full flex-col overflow-y-auto bg-background px-4 pb-12 pt-6 sm:px-6 lg:px-8">
            <div className="size-full">
              <div className="pb-5 sm:flex sm:items-center sm:justify-between">
                <h2 className="py-2 text-xl font-semibold leading-6 text-gray-900">
                  Activity Log
                </h2>
                {hasAnyFilterSelected(filters) && (
                  <Button
                    label="Clear Filters"
                    background="bg-gray"
                    onClick={() => {
                      setFilters(DEFAULT_FILTERS)
                      getUpdatedActivityLog(
                        `${ACTIVITY_BASE_URL}?limit=${perPage}&page=${currentPage}`,
                      )
                    }}
                  />
                )}
              </div>
              <DataTable
                data={activityLog}
                columns={COLUMNS}
                pagination={pagination}
                hoverEffect={false}
                ref={dataTable}
                loading={loadingActivityLog}
                filters={filters}
                onFilter={(e) => {
                  setFilters(e.filters)
                }}
                sorting={sorting}
              />
            </div>
          </div>
        </div>
      </StateContainer>
    </PageContainer>
  )
})

export default ClientActivity
