// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the global benchmark imports.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getGlobalBenchmarkImports = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the global benchmark imports.
 * @param {string} importId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getGlobalBenchmarkImport = async (
  importId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(`/global-benchmark-imports/${importId}`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new global benchmark import.
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createGlobalBenchmarkImport = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post('/global-benchmark-imports/', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    setSuccess('New Global Benchmark Import created.')
    setLoading(false)

    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)

    return null
  }
}

/**
 * Gets the global benchmark creator list.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getGlobalBenchmarkCreators = async (setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const response = await axios.get('/global-benchmark-imports/creators')

    setLoading(false)
    return response.creators
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the global rules.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getGlobalRules = async (setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const response = await axios.get('/global-rules/default')

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the global rules using the specified `payload`.
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const updateGlobalRules = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch('/global-rules/default/', payload)

    setLoading(false)
    setSuccess('Rule Defaults updated.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
