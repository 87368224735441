/* eslint-disable indent */
import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { BoltIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'

// Components
import { DataTable } from '../DataTable'
import { Modal } from '../Modal'

// Store
import { TaskStoreContext } from '../../stores/TaskStore'

// Utils & Services
import {
  createClientQcCheckExport,
  getClientQcCheck,
  getClientQcCheckFlags,
} from '../../services/clients.service'
import { toast } from '../../utils/helpers'
import { usePagination } from '../../hooks/DataTableManagement'

const QcCheckModal = ({ client, closeModal, qcCheckId }) => {
  // Context
  const { addTask } = useContext(TaskStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [loadingFlags, setLoadingFlags] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [qcCheck, setQcCheck] = useState({})
  const [flags, setFlags] = useState([])

  // Pagination
  const { pagination, setTotalRecords } = usePagination(50)
  const { perPage, currentPage } = pagination

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  const dataTable = useRef()

  const getUpdatedFlags = async (url) => {
    const response = await getClientQcCheckFlags(url, handleErrors, setLoadingFlags)

    if (response) {
      setTotalRecords(response.count)
      setFlags(response.results)
    }
  }

  useEffect(() => {
    const getUpdatedData = async () => {
      const response = await getClientQcCheck(client.id, qcCheckId, handleErrors, setLoading)
      if (response) setQcCheck(response)
    }

    getUpdatedData()
  }, [qcCheckId])

  /**
   * When the filter, sort, current page, or row count changes, get the updated list of aum data.
   */
  useEffect(() => {
    if (qcCheck) {
      getUpdatedFlags(
        `/clients/${client.id}/qc-checks/${qcCheckId}/flags?expand=portfolio_data&limit=${perPage}&page=${currentPage}`,
      )
    }
  }, [qcCheck, perPage, currentPage])

  const options = [{ type: 'cancel', label: 'Cancel', onClick: closeModal }]

  const actions =
    qcCheck?.flags?.length === 0
      ? options
      : [
          ...options,
          {
            type: 'submit',
            label: 'Export QC Check',
            onClick: async () => {
              const response = await createClientQcCheckExport(
                client.id,
                qcCheck.id,
                handleErrors,
                setLoadingExport,
                handleSuccess,
              )

              if (response) {
                addTask({
                  type: 'qc-export',
                  id: response.id,
                  data: response,
                  downloaded: false,
                  fileName: `${client.name}_QC_Check.xlsx`,
                })
                closeModal()
              }
            },
          },
        ]

  const renderQcCheck = () => {
    if (!qcCheck || qcCheck.flags?.length === 0) {
      return <span className="text-center text-sm">No additional flags.</span>
    }

    const benchmarkFlags = _.filter(qcCheck.flags, (f) => f.type === 'Benchmark Check')
    const filteredFlags = _.filter(flags, (f) => f.type !== 'Benchmark Check')
    return (
      <div className="flex size-full flex-col gap-2 overflow-y-auto">
        <div className="mt-2 flex flex-col">
          <span className="font-semibold">General Checks:</span>
          {_.join(
            _.map(
              _.filter(['benchmarkDataOut', 'compositeCheck', 'eoMonthCheck'], (k) => qcCheck[k]),
              (k) => _.startCase(k),
            ),
            ', ',
          )}
        </div>

        {benchmarkFlags.length > 0 && (
          <div className="mt-2">
            <span className="font-semibold">Benchmark Checks:</span>
            <ul>
              {_.map(benchmarkFlags, (b) => (
                <li className="list-inside list-disc" key={b.id}>
                  {b.question}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="h-[450px] w-full">
          <DataTable
            columns={[
              {
                field: 'type',
                header: 'Flag',
                style: { minWidth: '200px' },
              },
              {
                field: 'portfolioData.account.name',
                header: 'Account Name',
                style: { minWidth: '200px' },
              },
              {
                field: 'portfolioData.account.number',
                header: 'Account Number',
                style: { minWidth: '200px' },
              },
              {
                field: 'portfolioData.composite.name',
                header: 'Composite',
                style: { minWidth: '250px' },
              },
              {
                field: 'question',
                header: 'Question',
                style: { minWidth: '300px' },
              },
            ]}
            data={filteredFlags}
            loading={loadingFlags}
            rowsPerPageOptions={[50, 100, 500, 1000]}
            pagination={pagination}
            ref={dataTable}
            scrollHeight="385px"
          />
        </div>
      </div>
    )
  }

  return (
    <Modal
      className="sm:w-[90vw] sm:max-w-screen-xl"
      icon={<BoltIcon className="h-6 fill-white stroke-white" />}
      open
      title="QC Check"
      loading={loadingExport}
      onClose={closeModal}
      content={
        <div className="flex size-full flex-col gap-4 pt-2">
          {loading ? (
            <div className="flex size-full flex-col items-center justify-center space-y-2 py-2">
              <span className="text-lg font-bold">Loading...</span>

              <span className="flex items-center pr-3">
                <div className="size-8">
                  {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
                  <svg className="size-8 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                    <ArrowPathIcon className="size-8" aria-hidden="true" />
                  </svg>
                </div>
              </span>
            </div>
          ) : (
            renderQcCheck()
          )}
        </div>
      }
      actions={actions}
    />
  )
}

QcCheckModal.propTypes = {
  client: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  qcCheckId: PropTypes.string.isRequired,
}

export default QcCheckModal
