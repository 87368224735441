import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { renderMultiSelectFilter } from '../../components/CustomEditor'
import { DataTable, DEFAULT_FILTER_OPTIONS } from '../../components/DataTable'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { Toggle } from '../../components/Toggle'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Services
import {
  deleteClient,
  getClients,
  getOwners,
  getProjectManagers,
  getResources,
} from '../../services/clients.service'

// Utils
import { configureFilterQuery, hasAnyFilterSelected, toast } from '../../utils/helpers'

const BASE_CLIENT_URL = '/clients/?expand=owner,project_manager,resource,most_recent_action'

const DEFAULT_FILTERS = {
  status: { value: [] },
  owner: { value: [] },
  project_manager: { value: [] },
  resource: { value: [] },
}

/**
 *
 * Clients
 *
 */
const Clients = observer(() => {
  // Context
  const { user, getUpdatedUser } = useContext(UserStoreContext)
  const {
    currentPage,
    perPage,
    sortedColumn,
    sortField,
    sortOrder,
    totalRecords,
    viewMyClients,
    searchTerm,
    search,
    first,
    setSearchTerm,
    toggleViewMyClients,
    setTotalRecords,
    setCurrentPage,
    handlePageChange,
    handleSortChange,
  } = useContext(ClientDashboardStoreContext)
  const navigate = useNavigate()
  const location = useLocation()

  // State
  const [loading, setLoading] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(null)
  const [error, setError] = useState(null)
  const [columns, setColumns] = useState([])
  const [updatedClients, setUpdatedClients] = useState([])
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const searchInputRef = useRef()

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  useEffect(() => {
    if (!location.pathname.includes('dashboard')) navigate('/clients', { replace: true })
  }, [])

  /**
   * Ensure that we have the most up-to-date user information.
   */
  useEffect(() => {
    getUpdatedUser()
  }, [])

  useEffect(() => {
    const getUpdatedData = async () => {
      const [owners, projectManagers, resources] = await Promise.all([
        getOwners(handleErrors),
        getProjectManagers(handleErrors),
        getResources(handleErrors),
      ])

      const updatedOwners = _.map(owners, (d) => ({
        label: `${d.firstName} ${d.lastName}`,
        value: d.id,
        id: d.id,
      }))
      const updatedProjectManagers = _.map(projectManagers, (d) => ({
        label: `${d.firstName} ${d.lastName}`,
        value: d.id,
        id: d.id,
      }))
      const updatedResources = _.map(resources, (d) => ({
        label: `${d.firstName} ${d.lastName}`,
        value: d.id,
        id: d.id,
      }))

      // Update editors and filters to include options
      const updatedColumns = [
        {
          field: 'name',
          header: 'Client Name',
          body: (row) => (
            <Link
              className="truncate text-sm font-medium leading-5 tracking-[0.25px] text-blue-800 hover:text-blue-dark"
              to={`/clients/${row.id}/dashboard`}
            >
              {row.name}
            </Link>
          ),
          sortable: true,
          sortField: 'name_sort',
        },
        {
          field: 'status',
          header: 'Status',
          body: (row) => row.status,
          sortable: true,
          style: { minWidth: '100px' },
          filterField: 'status',
          ...DEFAULT_FILTER_OPTIONS,
          filterElement: (options) =>
            renderMultiSelectFilter(
              [
                { label: 'Active', id: 'Active' },
                { label: 'Archived', id: 'Archived' },
              ],
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  status: {
                    value: selected,
                  },
                }))
              },
              'Filter by Status',
              options,
            ),
        },
        {
          field: 'portfolio_data_status',
          header: 'Data Status',
          body: (row) => row.portfolioDataStatus,
          sortable: true,
          style: { minWidth: '180px' },
        },
        {
          field: 'owner__first_name',
          header: 'Owner',
          body: (row) => (row.owner ? `${row.owner.firstName}` : 'N/A'),
          sortable: true,
          style: { minWidth: '150px' },
          filterField: 'owner',
          ...DEFAULT_FILTER_OPTIONS,
          filterElement: (options) =>
            renderMultiSelectFilter(
              updatedOwners,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  owner: {
                    value: selected,
                  },
                }))
              },
              'Filter by Owner',
              options,
            ),
        },
        {
          field: 'project_manager__first_name',
          header: 'Project Manager',
          body: (row) => (row.projectManager ? `${row.projectManager.firstName}` : 'N/A'),
          sortable: true,
          style: { minWidth: '200px' },
          filterField: 'project_manager',
          ...DEFAULT_FILTER_OPTIONS,
          filterElement: (options) =>
            renderMultiSelectFilter(
              updatedProjectManagers,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  project_manager: {
                    value: selected,
                  },
                }))
              },
              'Filter by Project Manager',
              options,
            ),
        },
        {
          field: 'resource__first_name',
          header: 'Resource',
          body: (row) => (row.resource ? `${row.resource.firstName}` : 'N/A'),
          sortable: true,
          style: { minWidth: '150px' },
          filterField: 'resource',
          ...DEFAULT_FILTER_OPTIONS,
          filterElement: (options) =>
            renderMultiSelectFilter(
              updatedResources,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  resource: {
                    value: selected,
                  },
                }))
              },
              'Filter by Resource',
              options,
            ),
        },
        {
          field: 'last_ctv6_run_at',
          header: 'CTV6 Last Run',
          body: (row) =>
            row.lastCtv6RunAt ? dayjs(row.lastCtv6RunAt).format('MM/DD/YYYY h:mm A') : 'N/A',
          sortable: true,
          style: { minWidth: '200px' },
        },
        {
          field: 'last_import_at',
          header: 'Import Last Run',
          body: (row) =>
            row.lastImportAt ? dayjs(row.lastImportAt).format('MM/DD/YYYY h:mm A') : 'N/A',
          sortable: true,
          style: { minWidth: '200px' },
        },
        {
          field: 'most_recent_action',
          header: 'Latest Action',
          body: (row) =>
            row.mostRecentAction?.description ? (
              <Link
                className="flex-wrap text-sm font-medium leading-5 tracking-[0.25px] text-blue-800 hover:text-blue-dark"
                to={`/clients/${row.id}/dashboard/activity-log`}
              >
                <b>{row.mostRecentAction.actor.firstName}</b> {row.mostRecentAction.description}
              </Link>
            ) : (
              'N/A'
            ),
          style: { minWidth: '250px' },
        },
        {
          field: 'view',
          header: 'Actions',
          center: 'true',
          body: (row) => (
            <Button
              background="bg-white"
              loading={loadingDelete === row.id}
              showConfirmDialog
              onClick={async () => {
                await deleteClient(
                  row.id,
                  handleErrors,
                  (l) => {
                    if (l) setLoadingDelete(row.id)
                    else setLoadingDelete(null)
                  },
                  async () => {
                    await getUpdatedClientsList(
                      `${BASE_CLIENT_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                    )
                    handleSuccess('Client deleted.')
                  },
                )
              }}
              label="Delete"
              type="button"
              disabled={row.status !== 'Archived'}
              confirm={{
                title: `Delete Client ${row.name}`,
                type: 'warning',
                message: `Are you sure you want to delete ${row.name}?`,
              }}
            />
          ),
          sortable: false,
          style: { minWidth: '250px' },
        },
      ]

      setColumns(updatedColumns)
    }

    getUpdatedData()
  }, [])

  /**
   * Handle invalid page error.
   *
   * If the error is 'Invalid page.', reset the current page to 1 and fetch the updated list of clients.
   * This occurs if, say, you're on page 10 and you filter the list so it only returns a few results.
   * Page 10 becomes invalid and needs to reset you to page 1.
   * This means that your page stays at 1 when you clear the search, which feels a little odd, but it's
   * better than the alternative of having the user stuck on an invalid page.
   */
  useEffect(() => {
    if (error === 'Invalid page.') {
      setCurrentPage(1)
      if (user && search) {
        getUpdatedClientsList(
          `${BASE_CLIENT_URL}&order_by=${sortedColumn}${
            search ? `&${search}` : ''
          }&limit=${perPage}&page=1`,
        )
      }
    }
  }, [error])

  /**
   * Gets the updated list of clients; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedClientsList = async (url) => {
    const response = await getClients(url, setError, setLoading, () => {})

    if (response) {
      setTotalRecords(response.count)
      setUpdatedClients(response.results)
    }
  }

  /**
   * Debounce the `getUpdatedClientsList`.
   * This will prevent the function from being called too frequently.
   */
  const debounceGetUpdatedClientsList = useCallback(_.debounce(getUpdatedClientsList, 500), [])

  /**
   * When the visibility, filter, sorted column, or row count changes, get the updated list of clients.
   */
  useEffect(() => {
    // Attach additional filter params if `viewMyClients` is toggled and the user has been loaded
    const filterByAssignee = viewMyClients && user?.id ? `&assignee=${user.id}` : ''

    const queryFilters = configureFilterQuery({
      status: filters.status || DEFAULT_FILTERS.status,
      owner: filters.owner || DEFAULT_FILTERS.owner,
      project_manager: filters.project_manager || DEFAULT_FILTERS.project_manager,
      resource: filters.resource || DEFAULT_FILTERS.resource,
    })

    debounceGetUpdatedClientsList(
      `${BASE_CLIENT_URL}&order_by=${sortedColumn}${
        search ? `&${search}` : ''
      }&limit=${perPage}&page=${currentPage}${filterByAssignee}&${queryFilters}`,
    )
  }, [user, search, filters, perPage, sortedColumn, viewMyClients, currentPage])

  return (
    <PageContainer withPadding>
      <StateContainer async error={error} loading={loading}>
        {() => (
          <div className="h-[calc(100vh-200px)] w-full">
            <div className="relative mx-1 mb-5 flex w-full flex-row">
              <h3 className="text-xl font-semibold leading-6 text-gray-900">Clients</h3>
            </div>

            <div className="mb-4 flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
              <TextInput
                className="w-full rounded-full py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
                icon={
                  <MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />
                }
                id="search"
                endIcon={
                  searchTerm ? (
                    <Button
                      type="button"
                      onClick={() => {
                        setSearchTerm('')
                        searchInputRef.current.value = ''
                      }}
                      icon={<XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />}
                      ariaLabel="Clear search"
                      iconOnly
                    />
                  ) : null
                }
                name="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
                placeholder="Looking for something?"
                ref={searchInputRef}
                value={searchTerm}
              />

              <div className="flex flex-row gap-2">
                {hasAnyFilterSelected(filters) && (
                  <Button
                    label="Clear Filters"
                    background="bg-gray"
                    onClick={() => {
                      setFilters(DEFAULT_FILTERS)
                    }}
                  />
                )}

                <Toggle
                  label="View Only My Clients"
                  disabled={loading}
                  id="viewMyClients"
                  name="viewMyClients"
                  onChange={toggleViewMyClients}
                  checked={viewMyClients}
                />
              </div>
            </div>

            <DataTable
              columns={columns}
              data={updatedClients}
              perPage={perPage}
              loading={loading}
              onRowClick={(row) => {
                navigate(`/clients/${row?.data.id}/dashboard`)
              }}
              pagination={{ first, perPage, currentPage, totalRecords, handlePageChange }}
              sorting={{ sortField, sortOrder, sortedColumn, handleSortChange }}
              filters={filters}
              onFilter={(e) => setFilters(e.filters)}
            />
          </div>
        )}
      </StateContainer>
    </PageContainer>
  )
})

export default Clients
