import React, { useState } from 'react'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  DocumentMagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import QcCheckModal from './QcCheckModal'
import RulesCheckModal from './RulesCheckModal'

// Utils
import { joinClassNames } from '../../utils/helpers'

/**
 * TaskProgress
 */
const TaskProgress = observer(({ clearTask, loading, task, isLast, type, updateTask }) => {
  // Context
  const location = useLocation()
  const navigate = useNavigate()

  // State
  const [showRulesCheckModal, setShowRulesCheckModal] = useState(false)
  const [showQcCheckModal, setShowQcCheckModal] = useState(false)

  const configureText = () => {
    if (!task) return `Unknown State`

    // Shared statuses
    if (task.status === 'Failed') {
      if (type === 'import' || type === 'import-benchmarks') return 'Import Failed, Try Again'
      if (type.includes('export')) return 'Export Failed, Try Again'
      return 'Report Generation Failed, Try Again'
    }
    if (task.status === 'Canceled') {
      if (type === 'import') return 'Data Import Canceled'
      if (type === 'export') return 'Export Canceled'
      return 'Report Generation Canceled'
    }

    // Data import task
    if (type === 'import') {
      if (task.status === 'Pending') return `Pending Data Import`
      if (task.status === 'Processing') return `Processing Data..`
      if (task.status === 'Importing') return `Importing Data..`
      if (task.status === 'Imported') return `Data Imported`
      return task.status
    }

    // Report generation task
    if (task.status === 'Expired') return `Report Generation Expired, Try Again`
    if (task.status === 'Processing Data') return `Processing Data..`
    if (task.status === 'Ready for Report Generation') return `Ready for Report Generation..`
    if (task.status === 'Preparing Reports') return `Preparing Reports..`
    if (task.status === 'Complete') {
      if (type === 'import') return 'Data Import Finished'
      if (type === 'export') return 'Export Finished'
      if (type === 'qc-check') return 'QC Check Completed'
      if (type === 'rules-check') return 'SCF and Mins Check Completed'
      return 'Report Generation Finished'
    }
    return task.status
  }

  const renderStatusIcon = () => {
    if (loading) {
      return (
        <div className="size-6">
          {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
          <svg className="size-6 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
            <ArrowPathIcon className="size-6" aria-hidden="true" />
          </svg>
        </div>
      )
    }

    if (task && (task.status === 'Complete' || task.status === 'Imported')) {
      return <CheckCircleIcon className="size-6 text-success" />
    }

    if (
      task &&
      (task.status === 'Ready for Initial Review' ||
        task.status === 'Ready for Review' ||
        task.status === 'Awaiting Approval')
    ) {
      return (
        <div className="size-6 rounded-full bg-blue p-1">
          <DocumentMagnifyingGlassIcon className="text-white" />
        </div>
      )
    }

    return <XCircleIcon className="size-6 text-error-icon" />
  }

  let label = `Data Import for ${task?.client?.name}`
  if (type === 'report') {
    label = `Report Generation for ${task?.client?.name}`
  } else if (type === 'export') {
    label = `Data Export for ${task?.client?.name}`
  } else if (type === 'qc-export') {
    label = `QC Check Export for ${task?.client?.name}`
  } else if (type === 'import-benchmarks') {
    label = 'Global Benchmark Import'
  } else if (type === 'qc-check') {
    label = `QC Check for ${task?.client?.name}`
  } else if (type === 'rules-check') {
    label = `SCF and Mins Check for ${task?.client?.name}`
  }

  const processing =
    task?.status !== 'Pending' &&
    task?.status !== 'Processing Data' &&
    task?.status !== 'Processing'

  const redirectAndOpenResults = (func) => {
    // If the user isn't viewing this client, redirect them
    if (!location.pathname.includes(task?.client?.id)) {
      navigate(`/clients/${task?.client?.id}/dashboard/`)
    }

    func(true)
  }

  const renderViewButton = () => {
    if (type === 'export' || type === 'qc-export' || type === 'import-benchmarks') return null
    if (
      (type === 'qc-check' || type === 'rules-check') &&
      (task?.status === 'Pending' || task?.status === 'Processing' || task?.status === 'Failed')
    )
      return null

    let url = null
    let onClick = () => {}
    if (type === 'report') url = `/clients/${task?.client?.id}/dashboard/reports/${task.id}`
    else if (type === 'import') url = `/clients/${task?.client?.id}/imports/new`
    else if (type === 'qc-check') onClick = () => redirectAndOpenResults(setShowQcCheckModal)
    else if (type === 'rules-check') onClick = () => redirectAndOpenResults(setShowRulesCheckModal)

    return (
      <div className="flex h-0 w-20 flex-1">
        <a
          href={url}
          onClick={onClick}
          type="button"
          className="flex w-full cursor-pointer items-center justify-center border border-transparent px-4 py-3 text-sm font-medium text-blue-600 hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          View
        </a>
      </div>
    )
  }

  return (
    <>
      <div
        className={joinClassNames(
          'flex w-full flex-col items-center space-y-4 sm:items-end',
          !isLast ? 'border-b border-gray-200' : '',
        )}
      >
        <div className="ring-bottom pointer-events-auto flex w-full max-w-md divide-x divide-gray-200">
          <div className="flex w-0 flex-1 items-center p-4">
            <div className="w-full">
              <div className="no-scrollbar flex flex-col gap-1">
                <div className="flex flex-row items-center gap-2">
                  {renderStatusIcon()} <p className="text-sm font-medium">{label}</p>
                </div>
              </div>

              <p className="mt-1 text-sm text-gray-500">
                Started at {dayjs(task.createdAt).format('MM/DD HH:mm A')}
              </p>
              <p className="mt-1 text-sm text-gray-500">{configureText()}</p>
              {task.statusMessage && (
                <span className="mb-1 text-center text-xs text-error">{task.statusMessage}</span>
              )}
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col divide-y divide-gray-200">
              {renderViewButton()}

              {processing && (
                <div className="flex h-0 w-20 flex-1">
                  <button
                    onClick={(e) => {
                      e.stopPropagation()

                      // Clear the interval and task to close the progress listener
                      clearTask()
                    }}
                    type="button"
                    className="flex w-full items-center justify-center border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Dismiss
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showQcCheckModal && (
        <QcCheckModal
          client={task?.client}
          closeModal={() => setShowQcCheckModal(false)}
          qcCheckId={task?.qcCheck.id}
        />
      )}

      {showRulesCheckModal && (
        <RulesCheckModal
          client={task?.client}
          closeModal={() => setShowRulesCheckModal(false)}
          rulesCheckId={task?.id}
          updateTask={updateTask}
        />
      )}
    </>
  )
})

export default TaskProgress
