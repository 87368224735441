import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

// Components
import { Button } from '../../components/Button'
import { CalendarInput } from '../../components/CalendarInput'
import { Select } from '../../components/Select'
import { SidePanel } from '../../components/SidePanel'
import { TextArea } from '../../components/TextArea'
import { TextInput } from '../../components/TextInput'

import { DEFAULT_RULES } from '../GlobalRules/constants'

// Utils
import { toast } from '../../utils/helpers'

const RulesPanel = ({ addRule, clientId, closePanel, composites, rule, updateRule }) => {
  // State
  const [loading, setLoading] = useState(false)

  const handleError = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm()

  const RULES = {
    ...DEFAULT_RULES,
    base: {
      ...(composites && {
        composite: {
          type: 'select',
          name: 'composite',
          label: 'Composite',
          options: composites,
        },
      }),
      periodFrom: {
        type: 'date',
        name: 'periodFrom',
        label: 'Period From',
      },
      periodTo: {
        type: 'date',
        name: 'periodTo',
        label: 'Period To',
      },
      ...DEFAULT_RULES.base,
    },
  }

  useEffect(() => {
    if (rule) {
      // Update all rule fields that have options with the matching option object
      const categoryKeys = _.keys(RULES)
      const updatedRules = _.mapValues(rule, (value, key) => {
        let updatedValue = value
        const category = categoryKeys.find((k) => RULES[k][key])

        if (!RULES[category]) return value
        if (key === 'composite') {
          updatedValue = composites.find((c) => c.value === value.id)
        } else if (RULES[category][key]?.type === 'select') {
          const option = RULES[category][key].options.find((o) => o.value === value)
          updatedValue = option
        } else if (RULES[category][key]?.type === 'date') {
          updatedValue = value ? dayjs(value).toDate() : null
        } else if (RULES[category][key]?.format === 'percent') {
          updatedValue = value ? value * 100 : null
        }

        return updatedValue
      })

      reset(updatedRules)
    }
  }, [rule])

  /**
   * Handles submitting the updates to the rule.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const updatedData = { ...data }

    // Update all rule fields that have options with the matching option value
    const categoryKeys = _.keys(RULES)
    _.forEach(data, (value, key) => {
      const category = categoryKeys.find((k) => RULES[k][key])

      if (!RULES[category]) return
      if (RULES[category][key]?.type === 'select') {
        updatedData[key] = value?.value
      } else if (RULES[category][key]?.type === 'date') {
        updatedData[key] = value ? dayjs(value).format('YYYY-MM-DD') : null
      } else if (
        RULES[category][key]?.type === 'number' &&
        RULES[category][key]?.format === 'percent'
      ) {
        updatedData[key] = value ? parseFloat(value) / 100 : null
      } else if (RULES[category][key]?.type === 'number') {
        updatedData[key] = value ? parseFloat(value) : null
      }
    })

    if (!rule) {
      await addRule(clientId, updatedData, handleError, setLoading, (m) => {
        handleSuccess(m)
        closePanel()
      })
    } else {
      await updateRule(clientId, updatedData, handleError, setLoading, (m) => {
        handleSuccess(m)
        closePanel()
      })
    }
  }

  const renderField = (field) => {
    switch (field.type) {
      case 'text': {
        return (
          <TextInput
            key={field.name}
            disabled={loading}
            fullWidth
            label={field.label}
            name={field.name}
            error={errors[field.name] && 'This field is required'}
            {...register(field.name)}
          />
        )
      }
      case 'textarea': {
        return (
          <TextArea
            key={field.name}
            disabled={loading}
            fullWidth
            label={field.label}
            name={field.name}
            error={errors[field.name] && 'This field is required'}
            {...register(field.name)}
          />
        )
      }
      case 'number': {
        let endIcon = null
        let startIcon = null
        if (field.format === 'percent') endIcon = <span>%</span>
        else if (field.format === 'currency') startIcon = <span>$</span>
        else if (field.format) endIcon = <span>{field.format}</span>

        return (
          <TextInput
            key={field.name}
            disabled={loading}
            endIcon={endIcon}
            icon={startIcon}
            fullWidth
            label={field.label}
            name={field.name}
            type="number"
            error={errors[field.name] && errors[field.name].message}
            {...register(field.name, {
              validate: (value) => {
                if (!value) return true
                // Disable validation for now - this needs a closer look. Not all models use the
                // same digit and decimal settings.
                // if (value?.toString().length > 19) return 'No more than 19 digits'
                // if (!/^\d*(\.\d{0,15})?$/.test(value)) return 'No more than 15 decimals'
                return true
              },
            })}
          />
        )
      }
      case 'select': {
        return (
          <Controller
            key={field.name}
            disabled={loading}
            name={field.name}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label={field.label}
                style={{ width: '100%' }}
                onChange={(option) => {
                  onChange(option)
                  clearErrors(field.name)
                }}
                options={field.options}
              />
            )}
          />
        )
      }
      case 'date': {
        return (
          <Controller
            key={field.name}
            disabled={loading}
            name={field.name}
            control={control}
            render={({ field: { value } }) => (
              <CalendarInput
                label={field.label}
                id={field.name}
                error={errors[field.name] && 'This field is required'}
                value={value}
                onChange={(d) => setValue(field.name, d)}
              />
            )}
          />
        )
      }
      default:
        return null
    }
  }

  const renderCollapsedSection = (label, section) => (
    <Disclosure>
      {({ open }) => (
        <div>
          <DisclosureButton
            className={mergeClassNames(
              'text-primary group flex w-full justify-between bg-gray-50 px-4 py-2 text-left text-sm font-semibold',
              open ? 'rounded-t-lg' : 'rounded-lg',
            )}
          >
            <span>{label}</span>
            <ChevronDownIcon className="block size-6 fill-blue group-data-[open]:hidden group-data-[hover]:fill-blue-dark" />
            <ChevronUpIcon className="ml-2 hidden size-6 fill-blue group-data-[open]:block group-data-[hover]:fill-blue-dark" />
          </DisclosureButton>

          <DisclosurePanel className="text-primary rounded-b-lg bg-gray-50 p-4 text-sm">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {_.map(RULES[section], (r) => renderField(r))}
            </div>
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  )

  return (
    <SidePanel open setOpen={closePanel} title={rule?.id ? 'Edit Rule' : 'Add Rule'}>
      <div className="flex flex-col gap-4">
        {/* Render fields in pairs of two */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {_.map(RULES.base, (r) => renderField(r))}
        </div>
      </div>

      {renderCollapsedSection('GIPS', 'gips')}
      {renderCollapsedSection('PNP', 'pnp')}
      {renderCollapsedSection('Advanced', 'advanced')}

      <div className="flex flex-row justify-end gap-4">
        <Button background="bg-gray" label="Cancel" loading={loading} onClick={closePanel} />
        <Button
          label={rule?.id ? 'Update' : 'Save'}
          loading={loading}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </SidePanel>
  )
}

RulesPanel.propTypes = {
  clientId: PropTypes.string.isRequired,
  closePanel: PropTypes.func.isRequired,
  composites: PropTypes.array.isRequired,
  rule: PropTypes.object.isRequired,
  addRule: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
}

export default RulesPanel
