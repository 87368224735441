import PropTypes from 'prop-types'
import React from 'react'

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'

const ActivityVerbIcon = ({ verb }) => {
  const VERB_ICONS = {
    approved: <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />,
    generated: <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />,
    canceled: <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />,
    failed: <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />,
  }
  const defaultIcon = (
    <div aria-hidden="true" className="size-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
  )

  return VERB_ICONS[verb] || defaultIcon
}

export default ActivityVerbIcon

ActivityVerbIcon.propTypes = {
  verb: PropTypes.string,
}
